import { useTheme, styled } from '@mui/material/styles';
import { Box, Button, Grid, Stack, Typography, useMediaQuery, Link, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

// Project imports
import { GOOGLE } from 'config';
import useAsync from 'hooks/useAsync';
import AuthFooter from 'components/AuthFooter';
import PreviewLabel from 'components/PreviewLabel';
import InlineMessage from 'components/InlineMessage';
import { auth } from '../firebase';

const PageWrapper = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.primary.light,
    minHeight: '100vh',
}));

const Login = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();

    const requestToSignInWithGoogle = useAsync(async () => {
        const googleProvider = new GoogleAuthProvider();

        // @todo: prompt: consent is going to cause a lot of refresh tokens -
        // leaving it in so we can test, but we will need to be smarter
        // about when it is added
        googleProvider.setCustomParameters({
            access_type: 'offline',
            prompt: 'consent',
        });

        GOOGLE.scopes.map((scope) => {
            googleProvider.addScope(`https://www.googleapis.com/auth/${scope}`);
        });

        await signInWithPopup(auth, googleProvider);

        navigate('/projects');
    });

    return (
        <PageWrapper>
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{ minHeight: 'calc(100vh - 68px)' }}
                    >
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            <Paper
                                square
                                sx={{
                                    'maxWidth': { xs: 400, lg: 450 },
                                    'margin': { xs: 2.5, md: 3 },
                                    '& > *': {
                                        flexGrow: 1,
                                        flexBasis: '50%',
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        bgcolor: 'primary.main',
                                        color: 'primary.light',
                                        px: 2,
                                        py: 3,
                                        textAlign: 'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            mb: 0.5,
                                        }}
                                    >
                                        <Typography variant="h1" color="inherit" sx={{ fontWeight: 600 }}>
                                            PAD UI
                                        </Typography>
                                        <PreviewLabel />
                                    </Box>

                                    <Typography variant="h4" color="inherit" sx={{ fontWeight: 500 }}>
                                        From Community Tech Alliance
                                    </Typography>
                                </Box>
                                <Box sx={{ p: { xs: 2, sm: 3, lg: 4, xl: 5 } }}>
                                    <Grid container spacing={2} alignItems="center" justifyContent="center">
                                        <Grid item xs={12}>
                                            <Grid
                                                container
                                                direction={matchDownSM ? 'column-reverse' : 'row'}
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Grid item>
                                                    <Stack alignItems="center" justifyContent="center" spacing={1}>
                                                        <Typography
                                                            variant="caption"
                                                            fontSize="16px"
                                                            color="inherit"
                                                            textAlign={matchDownSM ? 'center' : 'inherit'}
                                                        >
                                                            Sign in using your CTA-provided Google account
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container direction="column" justifyContent="center" spacing={2}>
                                                <Grid item xs={12}>
                                                    <Button
                                                        disableElevation
                                                        fullWidth
                                                        onClick={() => requestToSignInWithGoogle.perform()}
                                                        disabled={requestToSignInWithGoogle.isRunning}
                                                        size="large"
                                                        variant="outlined"
                                                        sx={{
                                                            'display': 'flex',
                                                            'alignItems': 'center',
                                                            'color': 'inherit',
                                                            'backgroundColor':
                                                                theme.palette.mode === 'dark'
                                                                    ? theme.palette.dark.main
                                                                    : theme.palette.grey[50],
                                                            'borderColor':
                                                                theme.palette.mode === 'dark'
                                                                    ? theme.palette.dark
                                                                    : theme.palette.primary.dark,
                                                            'mb': 2,
                                                            '&:hover': {
                                                                backgroundColor: theme.palette.grey[200],
                                                            },
                                                        }}
                                                    >
                                                        <img
                                                            src="/social-google.svg"
                                                            alt="Google icon"
                                                            width={20}
                                                            height={20}
                                                            style={{ marginRight: matchDownSM ? 8 : 16 }}
                                                        />
                                                        {requestToSignInWithGoogle.isRunning
                                                            ? 'Google sign in pending...'
                                                            : 'Sign in with Google'}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {requestToSignInWithGoogle.didFail && (
                                            <InlineMessage message="Error logging in. Please try again." type="error" />
                                        )}
                                    </Grid>
                                </Box>
                            </Paper>
                            <Stack
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                    maxWidth: { xs: 400, lg: 450 },
                                    margin: { xs: 2.5, md: 3 },
                                }}
                            >
                                <Typography variant="caption" fontSize="12px" color="inherit" textAlign="center">
                                    Trouble logging in? Check our{' '}
                                    <Link href="https://cta.statuspage.io/" target="_blank">
                                        Status Page
                                    </Link>
                                    &nbsp;or click the &apos;Help&apos; button below
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
                    <AuthFooter />
                </Grid>
            </Grid>
        </PageWrapper>
    );
};

export default Login;
