export default function loadScript({ id, src, defer = true }) {
    const script = document.createElement('script');

    script.setAttribute('id', id);
    script.setAttribute('src', src);

    if (defer) {
        script.setAttribute('defer', true);
    } else {
        script.setAttribute('async', true);
    }

    script.onload = () => {
        // eslint-disable-next-line no-console
        console.log(`${id} script loaded`);
    };

    script.onerror = () => {
        // eslint-disable-next-line no-console
        console.log(`Error occured while loading ${id} script`);
    };

    document.body.appendChild(script);
}
