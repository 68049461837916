import { Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useLoaderData, useNavigate } from 'react-router-dom';

import { PROJECT_FEATURE_FLAGS, SYNC_POINT_STATUSES, SYNC_POINT_TYPES } from '@cta-pond/constants';

import EmptyStatePlaceholder from 'components/EmptyStatePlaceholder';
import InlineMessage from 'components/InlineMessage';
import NewSourceDropdown from 'components/NewSourceDropdown';
import PageHeader from 'components/PageHeader';
import SyncPointLogo from 'components/SyncPointLogo';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useSyncPointMetadata from 'hooks/useSyncPointMetadata';

function SyncPointCard({ point, onClickCreateSync, ...rest }) {
    SyncPointCard.propTypes = {
        point: PropTypes.shape({
            id: PropTypes.string.isRequired,
            type: PropTypes.oneOf(Object.values(SYNC_POINT_TYPES)).isRequired,
            status: PropTypes.oneOf(Object.values(SYNC_POINT_STATUSES)).isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired,
        onClickCreateSync: PropTypes.func.isRequired,
    };

    const { id, type, status, name } = point;
    const { singularName } = useSyncPointMetadata(type);

    const statusMessageTypes = {
        [SYNC_POINT_STATUSES.ERROR]: 'error',
        [SYNC_POINT_STATUSES.PROCESSING]: 'info',
        [SYNC_POINT_STATUSES.BUILDING]: 'info',
    };

    const statusMessages = {
        [SYNC_POINT_STATUSES.ERROR]: 'Connection failed',
        [SYNC_POINT_STATUSES.PROCESSING]: 'Source is processing',
        [SYNC_POINT_STATUSES.BUILDING]: 'Source is queued',
    };

    return (
        <Card square variant="outlined" sx={{ height: '100%' }} {...rest}>
            <CardContent sx={{ px: 1, pt: 2, pb: 0, mb: 1 }}>
                <SyncPointLogo pointType={type}>
                    <Typography variant="h4" sx={{ mb: 1 }}>
                        {name}
                    </Typography>
                    {singularName && <Typography variant="h5">{singularName}</Typography>}
                </SyncPointLogo>
            </CardContent>
            <CardActions disableSpacing={true} sx={{ py: 2, display: 'flex', justifyContent: 'space-between' }}>
                {status === SYNC_POINT_STATUSES.CONNECTED ? (
                    <Button
                        fullWidth
                        onClick={() => onClickCreateSync(id)}
                        variant="outlined"
                        aria-label={'Create sync'}
                    >
                        Create sync
                    </Button>
                ) : (
                    <InlineMessage
                        message={statusMessages[status]}
                        type={statusMessageTypes[status]}
                        sxOverrides={{ mt: 1 }}
                    />
                )}
            </CardActions>
        </Card>
    );
}

export default function SourcesIndexPage() {
    const featureFlags = useFeatureFlags();
    const navigate = useNavigate();
    const { sources = [] } = useLoaderData();
    const doesProjectHaveSources = Boolean(sources?.length);

    const onClickCreateSync = (sourceId) => navigate(`../syncs/new?sourceId=${sourceId}`);

    return (
        <>
            <PageHeader
                title="Sources"
                subtitle="View and manage all your sync sources in one place"
                showDemoLabel={featureFlags[PROJECT_FEATURE_FLAGS.IS_HOPPER_DEMO_ENABLED]}
                action={doesProjectHaveSources ? <NewSourceDropdown /> : null}
            />
            {doesProjectHaveSources ? (
                <Grid container component={'section'} spacing={2}>
                    {sources.map((source, index) => (
                        <Grid item key={`source-grid-item-${index}`} xs={12} md={6} lg={4} xl={3}>
                            <SyncPointCard
                                point={source}
                                data-testid={`source-card-${index}`}
                                onClickCreateSync={onClickCreateSync}
                            />
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <EmptyStatePlaceholder title={'No sources yet'} subtitle={'Connect a new source to get started'}>
                    <NewSourceDropdown />
                </EmptyStatePlaceholder>
            )}
        </>
    );
}
