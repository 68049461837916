import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import SyncPointLogo from 'components/SyncPointLogo';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { DEFAULT_SOURCE_TYPES, SYNC_POINT_ROUTE_PARAMS, SYNC_POINT_TYPES } from '@cta-pond/constants';

import useSyncPointMetadata from 'hooks/useSyncPointMetadata';

function NewSyncPointDropdownMenuItem({ pointType, ...rest }) {
    NewSyncPointDropdownMenuItem.propTypes = {
        pointType: PropTypes.oneOf(Object.values(SYNC_POINT_TYPES)).isRequired,
    };

    const { singularName } = useSyncPointMetadata(pointType);

    return (
        <MenuItem {...rest}>
            <SyncPointLogo pointType={pointType}>
                <Typography variant="h5">{singularName}</Typography>
            </SyncPointLogo>
        </MenuItem>
    );
}

export default function NewSourceDropdown() {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const { projectId } = useParams();

    const isMenuOpen = Boolean(anchorEl);

    const buttonLabel = 'Connect new source';

    const handleClickButton = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickMenuItem = (sourceType) => {
        const routeId = SYNC_POINT_ROUTE_PARAMS[sourceType];
        if (routeId) navigate(`/projects/${projectId}/sources/new/${routeId}`);
    };

    return (
        <>
            <Button
                id="new-source-menu-button"
                aria-label={buttonLabel}
                aria-haspopup="true"
                aria-controls={isMenuOpen ? 'new-source-menu' : undefined}
                aria-expanded={isMenuOpen ? 'true' : undefined}
                variant="contained"
                color="secondary"
                disableElevation
                onClick={handleClickButton}
                endIcon={<KeyboardArrowDownIcon />}
            >
                {buttonLabel}
            </Button>
            <Menu
                id="new-source-menu"
                MenuListProps={{
                    'aria-labelledby': 'new-source-menu-button',
                }}
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={() => setAnchorEl(null)}
                elevation={0}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {DEFAULT_SOURCE_TYPES.map((sourceType, index) => (
                    <NewSyncPointDropdownMenuItem
                        pointType={sourceType}
                        key={`new-source-menu-item-${index}`}
                        onClick={() => handleClickMenuItem(sourceType)}
                    />
                ))}
            </Menu>
        </>
    );
}
