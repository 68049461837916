import PropTypes from 'prop-types';
import { Box } from '@mui/material';

export default function SyncWizardFooterWrapper({ children, sxOverrides = {} }) {
    SyncWizardFooterWrapper.propTypes = {
        children: PropTypes.node.isRequired,
        sxOverrides: PropTypes.object,
    };

    return <Box sx={{ pt: 2, display: 'flex', ...sxOverrides }}>{children}</Box>;
}
