// Material-ui
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, Button, Chip, IconButton, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useRouteLoaderData } from 'react-router-dom';

// Project imports
import useLayoutState from 'hooks/useLayoutState';
import useAsync from 'hooks/useAsync';
import PreviewLabel from 'components/PreviewLabel';
import { auth } from '../firebase';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();
    const { isSidebarDrawerOpen, toggleIsSidebarDrawerOpen } = useLayoutState();
    const { loggedInUser } = useRouteLoaderData('projects');

    const requestToLogout = useAsync(async () => {
        // Redirect will be handled by onIdTokenChanged listener in router
        await auth.signOut();
    });

    return (
        <AppBar
            enableColorOnDark
            position="fixed"
            elevation={0}
            sx={{
                bgcolor: 'primary.main',
                color: 'primary.light',
                transition: isSidebarDrawerOpen ? theme.transitions.create('width') : 'none',
            }}
        >
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexFlow: { xs: 'column', md: 'row' },
                    py: '12px',
                }}
            >
                {/* logo & toggler button */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        [theme.breakpoints.down('md')]: {
                            width: 'auto',
                            marginBottom: 2,
                        },
                    }}
                >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={() => toggleIsSidebarDrawerOpen()}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                        <Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                                <Typography variant="h2" color="inherit" sx={{ fontWeight: 600 }}>
                                    PAD UI
                                </Typography>
                                <PreviewLabel />
                            </Box>

                            <Typography variant="h5" color="inherit" sx={{ fontWeight: 500 }}>
                                From Community Tech Alliance
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ flexGrow: 1 }} />

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {loggedInUser?.claims?.isSuperUser && (
                        <Chip
                            variant="filled"
                            color="secondary"
                            size="small"
                            label={'Super User'}
                            sx={{ mr: 1, fontWeight: 'bold' }}
                        />
                    )}
                    <Typography variant="h5" color="inherit" sx={{ mr: '12px', fontWeight: 600 }}>
                        Logged in as {loggedInUser?.displayName}
                    </Typography>
                    <Button
                        variant="outlined"
                        color="inherit"
                        sx={{ fontWeight: 600 }}
                        disabled={requestToLogout.isRunning}
                        onClick={() => requestToLogout.perform()}
                    >
                        Log out
                    </Button>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
