import { Backdrop, Box, CircularProgress, Paper, Step, StepLabel, Stepper, Typography } from '@mui/material';
import PropTypes from 'prop-types';

export default function SyncWizard({
    steps,
    activeStepIndex,
    isLoading = false,
    loadingMessage = 'Loading...',
    loadingBackdropTestId = 'sync-wizard-backdrop',
    children = null,
}) {
    SyncWizard.propTypes = {
        steps: PropTypes.arrayOf(PropTypes.object).isRequired,
        activeStepIndex: PropTypes.number.isRequired,
        isLoading: PropTypes.bool,
        loadingMessage: PropTypes.string,
        loadingBackdropTestId: PropTypes.string,
        children: PropTypes.node,
    };

    if (!children && !steps.every(({ content }) => content)) {
        throw new Error('SyncWizard must have either children or steps with content');
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ maxWidth: 800, width: '100%' }}>
                <Stepper activeStep={activeStepIndex} sx={{ mb: 2 }}>
                    {steps.map(({ label }, idx) => (
                        <Step key={label}>
                            <StepLabel
                                StepIconProps={{
                                    'data-testid': `step-icon-${idx + 1}`,
                                }}
                            >
                                {label}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Paper variant="outlined" square={true} sx={{ p: 3, position: 'relative' }}>
                    <Backdrop
                        data-testid={loadingBackdropTestId}
                        sx={{
                            color: 'primary.light',
                            position: 'absolute',
                            display: 'flex',
                            flexDirection: 'column',
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={isLoading}
                    >
                        <CircularProgress sx={{ mb: 2, color: 'inherit' }} />
                        <Typography variant="h5" sx={{ color: 'inherit' }}>
                            {loadingMessage}
                        </Typography>
                    </Backdrop>
                    {children && children}
                    {!children && steps[activeStepIndex].content}
                </Paper>
            </Box>
        </Box>
    );
}
