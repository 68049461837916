import { Autocomplete, TextField, Typography } from '@mui/material';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { getAuthedFetchConfig } from 'helpers/auth';
import useAsync from 'hooks/useAsync';

export default function BigQueryTableSelector({
    projectId,
    onSelectDataset,
    onSelectTable,
    sourceDataset = null,
    datasetsFilter = null,
    sourceTable = null,
    showAllErrors = false,
    heading = 'Choose BigQuery Data Source',
}) {
    BigQueryTableSelector.propTypes = {
        projectId: PropTypes.string.isRequired,
        onSelectDataset: PropTypes.func.isRequired,
        onSelectTable: PropTypes.func.isRequired,
        sourceDataset: PropTypes.string,
        datasetsFilter: PropTypes.func,
        sourceTable: PropTypes.string,
        showAllErrors: PropTypes.bool,
        heading: PropTypes.string,
    };

    const [didUserTouchDatasetField, setDidUserTouchDatasetField] = useState(false);
    const [didUserTouchTableField, setDidUserTouchTableField] = useState(false);
    const showDatasetFieldError = !sourceDataset && (didUserTouchDatasetField || showAllErrors);
    const showTableFieldError = !sourceTable && (didUserTouchTableField || showAllErrors);

    const requestToFetchAvailableDatasets = useAsync(async () => {
        const authedRequestConfig = await getAuthedFetchConfig();

        const { data = [] } = await axios.get(
            `/api/big-query-schema?finder=datasetsInProject&projectId=${projectId}`,
            authedRequestConfig,
        );

        return data.map(({ datasetReference }) => datasetReference.datasetId);
    });

    const requestToFetchAvailableTables = useAsync(async () => {
        if (!sourceDataset) {
            return [];
        }

        const authedRequestConfig = await getAuthedFetchConfig();

        const { data = [] } = await axios.get(
            `/api/big-query-schema?finder=tablesInDataset&projectId=${projectId}&datasetId=${sourceDataset}`,
            authedRequestConfig,
        );

        return data.map(({ tableReference }) => tableReference.tableId);
    });

    useEffect(() => {
        requestToFetchAvailableDatasets.perform();
    }, []);

    useEffect(() => {
        requestToFetchAvailableTables.perform();
    }, [sourceDataset]);

    const { result: datasetsResult } = requestToFetchAvailableDatasets;
    const datasetsOptions = datasetsResult ? (datasetsFilter && datasetsFilter(datasetsResult)) || datasetsResult : [];

    return (
        <>
            <Typography variant="h4" sx={{ mb: 2 }}>
                {heading}
            </Typography>
            <Autocomplete
                id="source-dataset-selector"
                value={sourceDataset}
                onChange={onSelectDataset}
                loading={requestToFetchAvailableDatasets.isRunning}
                options={datasetsOptions}
                noOptionsText={'No datasets match the search term'}
                fullWidth={true}
                sx={{ mb: 1 }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={requestToFetchAvailableDatasets.isRunning ? 'Loading datasets...' : 'Select dataset'}
                        error={showDatasetFieldError}
                        onBlur={() => setDidUserTouchDatasetField(true)}
                        helperText={showDatasetFieldError ? 'Please select a source dataset' : ' '}
                    />
                )}
            />
            <Autocomplete
                id="source-table-selector"
                value={sourceTable}
                disabled={!sourceDataset}
                onChange={onSelectTable}
                loading={requestToFetchAvailableTables.isRunning}
                options={requestToFetchAvailableTables.result || []}
                noOptionsText={'No tables match the search term'}
                fullWidth={true}
                sx={{ mb: 1 }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={
                            requestToFetchAvailableTables.isRunning
                                ? 'Loading tables...'
                                : sourceDataset
                                  ? 'Select table'
                                  : 'Select dataset to load source tables'
                        }
                        disabled={!sourceDataset}
                        error={showTableFieldError}
                        onBlur={() => setDidUserTouchTableField(true)}
                        helperText={showTableFieldError ? 'Please select a source table' : ' '}
                    />
                )}
            />
        </>
    );
}
