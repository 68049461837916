import { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const LayoutContext = createContext(null);

export const LayoutProvider = ({ children }) => {
    LayoutProvider.propTypes = {
        children: PropTypes.node.isRequired,
    };

    const [isSidebarDrawerOpen, setIsSidebarDrawerOpen] = useState(true);

    return (
        <LayoutContext.Provider
            value={{
                isSidebarDrawerOpen,
                setIsSidebarDrawerOpen,
                toggleIsSidebarDrawerOpen: () => setIsSidebarDrawerOpen(!isSidebarDrawerOpen),
            }}
        >
            {children}
        </LayoutContext.Provider>
    );
};

export default LayoutContext;
