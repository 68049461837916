import { Box, Button } from '@mui/material';
import axios from 'axios';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import {
    GCP_WORKFLOW_EXECUTION_UI_TABLE_COLUMNS,
    GCP_WORKFLOW_IDS,
    PROJECT_FEATURE_FLAGS,
    VENDORS,
} from '@cta-pond/constants';
import EmptyStatePlaceholder from 'components/EmptyStatePlaceholder';
import GcpWorkflowExecutionsTable from 'components/GcpWorkflowExecutionsTable';
import PageHeader from 'components/PageHeader';
import SkeletonLoaderList from 'components/SkeletonLoaderList';
import { getAuthedFetchConfig } from 'helpers/auth';
import useAsync from 'hooks/useAsync';
import useFeatureFlags from 'hooks/useFeatureFlags';

export default function MatchingJobsIndexPage() {
    const { projectId } = useParams();
    const featureFlags = useFeatureFlags();

    let subtitle = 'Create and manage jobs to match your data with Catalist voter file data.';

    if (featureFlags[PROJECT_FEATURE_FLAGS.IS_TARGETSMART_MATCHING_ENABLED]) {
        subtitle = 'Create and manage jobs to match your data with TargetSmart voter file data.';
    }

    if (featureFlags[PROJECT_FEATURE_FLAGS.ARE_BOTH_MATCHING_PROVIDERS_ENABLED]) {
        subtitle = 'Create and manage jobs to match your data with voter file data from Catalist and TargetSmart.';
    }

    const requestToFetchMatchingWorkflowJobs = useAsync(async () => {
        const authedRequestConfig = await getAuthedFetchConfig();

        const { data } = await axios.get('/api/gcp-workflow-executions', {
            params: {
                projectId,
                workflowId: GCP_WORKFLOW_IDS.VOTERFILE_MATCHING,
            },
            ...authedRequestConfig,
        });

        // TODO [PAD-2707]: Move this filter server-side
        return data.filter(({ metadata = {} }) => {
            const { jobName, vendorId } = metadata;
            const hasJobName = Boolean(jobName);
            const isProviderEnabled =
                (featureFlags[PROJECT_FEATURE_FLAGS.IS_CATALIST_MATCHING_ENABLED] && vendorId === VENDORS.CATALIST) ||
                (featureFlags[PROJECT_FEATURE_FLAGS.IS_TARGETSMART_MATCHING_ENABLED] &&
                    vendorId === VENDORS.TARGETSMART);

            return hasJobName && isProviderEnabled;
        });
    });

    useEffect(() => {
        requestToFetchMatchingWorkflowJobs.perform();
    }, []);

    const CreateJobButton = () => {
        const buttonLabel = 'Create New Job';

        return (
            <Button component={Link} to="new" variant="contained" color="secondary" aria-label={buttonLabel}>
                {buttonLabel}
            </Button>
        );
    };

    return (
        <>
            <PageHeader title="Voter File Matching Jobs" subtitle={subtitle} action={<CreateJobButton />} />
            <Box component="section">
                {requestToFetchMatchingWorkflowJobs.hasNotBeenPerformed ||
                requestToFetchMatchingWorkflowJobs.isRunning ? (
                    <SkeletonLoaderList count={5} height={75} />
                ) : requestToFetchMatchingWorkflowJobs.didFail ? (
                    <EmptyStatePlaceholder
                        title={'Oops! Something went wrong.'}
                        subtitle={'Try refreshing the page or contact support if the problem persists.'}
                    />
                ) : requestToFetchMatchingWorkflowJobs.result?.length === 0 ? (
                    <EmptyStatePlaceholder
                        title={'No matching jobs to show'}
                        subtitle={'Once you create your first matching job it will show up here.'}
                    />
                ) : (
                    <GcpWorkflowExecutionsTable
                        executions={requestToFetchMatchingWorkflowJobs.result}
                        optionalVisibleColumns={
                            featureFlags[PROJECT_FEATURE_FLAGS.ARE_BOTH_MATCHING_PROVIDERS_ENABLED]
                                ? [GCP_WORKFLOW_EXECUTION_UI_TABLE_COLUMNS.VENDOR]
                                : []
                        }
                    />
                )}
            </Box>
        </>
    );
}
