import { SYNC_POINT_TYPES } from '@cta-pond/constants';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import { Box } from '@mui/material';
import useSyncPointMetadata from 'hooks/useSyncPointMetadata';
import { PropTypes } from 'prop-types';

export default function SyncPointLogo({ pointType, children = null, sx = {}, ...rest }) {
    SyncPointLogo.propTypes = {
        pointType: PropTypes.oneOf(Object.values(SYNC_POINT_TYPES)).isRequired,
        children: PropTypes.node,
        sx: PropTypes.object,
    };

    const { logoUrl, singularName } = useSyncPointMetadata(pointType);

    const logo = (
        <Box
            sx={{
                border: 1,
                borderColor: 'grey.300',
                p: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '50px',
            }}
        >
            {logoUrl ? (
                <img src={logoUrl} alt={`${singularName} logo`} width="30" height="30" />
            ) : (
                <StorageOutlinedIcon />
            )}
        </Box>
    );

    return children ? (
        <Box sx={{ display: 'flex', alignItems: 'center', px: 2, py: 1, ...sx }} {...rest}>
            {logo}
            <Box sx={{ ml: 2 }}>{children}</Box>
        </Box>
    ) : (
        logo
    );
}
