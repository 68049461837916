import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

export default function FullScreenLoader({ message = 'Gathering lily pads...' }) {
    FullScreenLoader.propTypes = {
        message: PropTypes.string,
    };

    return (
        <Box sx={{ mt: 16, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <img src="/prog-animated.gif" alt="A pending prog" width="300" />
            <Typography variant="h3">{message}</Typography>
        </Box>
    );
}
