// Material-ui
import { Link, Typography, Stack } from '@mui/material';

// ==============================|| FOOTER - AUTHENTICATION ||============================== //

const AuthFooter = () => (
    <Stack direction="row" justifyContent="left" spacing={2}>
        <Stack item="true">
            <Typography
                variant="subtitle2"
                component={Link}
                href="https://communitytechalliance.org"
                target="_blank"
                underline="hover"
            >
                &copy; {new Date().getFullYear()} Community Tech Alliance
            </Typography>
        </Stack>
        <Stack item="true">
            <Typography
                variant="subtitle2"
                component={Link}
                href="https://communitytechalliance.org/privacy-policy"
                target="_blank"
                underline="hover"
            >
                Privacy Policy
            </Typography>
        </Stack>
        <Stack item="true">
            <Typography
                variant="subtitle2"
                component={Link}
                href="https://www.communitytechalliance.org/acceptable-use-policy"
                target="_blank"
                underline="hover"
            >
                Acceptable Use Policy
            </Typography>
        </Stack>
    </Stack>
);

export default AuthFooter;
