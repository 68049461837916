import { Backdrop, CircularProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';

export default function AbsoluteBackdrop({ isLoading, loadingMessage = 'Loading...' }) {
    AbsoluteBackdrop.propTypes = {
        isLoading: PropTypes.bool.isRequired,
        loadingMessage: PropTypes.string,
    };

    return (
        <Backdrop
            data-testid="async-form-loading-backdrop"
            sx={{
                color: 'primary.light',
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={isLoading}
        >
            <CircularProgress sx={{ mb: 2, color: 'inherit' }} />
            <Typography variant="body1" sx={{ color: 'inherit' }}>
                {loadingMessage}
            </Typography>
        </Backdrop>
    );
}
