import {
    Autocomplete,
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

/**
 * Generic component to allow matching fields between source and destination
 * Handles disabled state & validation of options
 * Styled with maxheight & scrolling when 5+ fields are shown
 * @todo:
 * - add prop to reverse UI, i.e. show dropdowns on the left
 */
export default function FieldMatcher({
    allFields,
    matchedFields,
    onMatchChange,
    headerLabels,
    matchOptions = [],
    fieldValueKey = null,
    fieldLabelKey = null,
    areMatchOptionsLoading = false,
    showAllErrors = false,
    tableAriaLabel = 'Table to display and match destination fields with source fields',
    noOptionsText = 'No options match the search term',
}) {
    FieldMatcher.propTypes = {
        allFields: PropTypes.array.isRequired,
        matchedFields: PropTypes.object.isRequired,
        onMatchChange: PropTypes.func.isRequired,
        headerLabels: PropTypes.array.isRequired,
        matchOptions: PropTypes.array,
        fieldValueKey: PropTypes.string,
        fieldLabelKey: PropTypes.string,
        areMatchOptionsLoading: PropTypes.bool,
        showAllErrors: PropTypes.bool,
        areFieldsOnLeft: PropTypes.bool,
        tableAriaLabel: PropTypes.string,
        noOptionsText: PropTypes.string,
    };

    if (!allFields?.length) {
        throw new Error('allFields property must have nonzero length');
    }

    if (headerLabels?.length !== 3) {
        throw new Error('headerLabels property must have length 3');
    }

    const [dirtyFieldValues, setDirtyFieldValues] = useState({});

    const alreadyMatchedOptions = useMemo(() => new Set(Object.values(matchedFields)), [matchedFields]);
    const areAnyFieldsRequired = useMemo(() => allFields.some((field) => field.isRequired), [allFields]);

    return (
        <>
            <Box sx={{ maxHeight: '450px', overflowY: 'scroll', border: 1, borderColor: 'grey.300', padding: '8px' }}>
                <Table size="small" aria-label={tableAriaLabel} sx={{ height: '400px' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ pl: 1 }}>{headerLabels[0]}</TableCell>
                            <TableCell>{headerLabels[1]}</TableCell>
                            <TableCell sx={{ width: '275px' }}>{headerLabels[2]}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allFields.map((field, idx) => {
                            const { value, label, isRequired = false } = field;
                            const fieldValue = field?.[fieldValueKey] ?? value;
                            const fieldLabel = field?.[fieldLabelKey] ?? label;

                            return (
                                <TableRow key={`field-matcher-row-${idx}`}>
                                    <TableCell sx={{ pl: 1 }}>
                                        {fieldLabel}
                                        {isRequired && '*'}
                                    </TableCell>
                                    <TableCell>{fieldValue}</TableCell>
                                    <TableCell sx={{ pr: 0, pb: 0 }}>
                                        <Autocomplete
                                            id={`field-matcher-autocomplete-${idx}`}
                                            value={matchedFields[fieldValue] || null}
                                            onChange={(_, newMatch) => {
                                                onMatchChange(fieldValue, newMatch);
                                            }}
                                            getOptionDisabled={(option) => alreadyMatchedOptions?.has(option)}
                                            disabledItemsFocusable={true}
                                            loading={areMatchOptionsLoading}
                                            options={matchOptions}
                                            noOptionsText={noOptionsText}
                                            fullWidth={true}
                                            renderInput={(params) => {
                                                const showFieldErrorMessage =
                                                    !matchedFields[fieldValue] &&
                                                    isRequired &&
                                                    (dirtyFieldValues[fieldValue] || showAllErrors);

                                                return (
                                                    <TextField
                                                        {...params}
                                                        sx={{ mt: 2 }}
                                                        label="Select matching column"
                                                        error={showFieldErrorMessage}
                                                        helperText={
                                                            showFieldErrorMessage ? 'This field is required.' : ' '
                                                        }
                                                        onBlur={() =>
                                                            setDirtyFieldValues({
                                                                ...dirtyFieldValues,
                                                                [fieldValue]: true,
                                                            })
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Box>
            {areAnyFieldsRequired && (
                <Typography sx={{ mt: 1 }}>
                    <em>* indicates a required field</em>
                </Typography>
            )}
        </>
    );
}
