import { ENVIRONMENTS, REMOTE_CONFIG_PARAMETER_DEFAULTS } from '@cta-pond/constants';
import config, { FIREBASE_API } from 'config';
import { initializeApp } from 'firebase/app';
import { ReCaptchaEnterpriseProvider, initializeAppCheck } from 'firebase/app-check';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore/lite';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { getPerformance, initializePerformance, trace } from 'firebase/performance';
import { fetchAndActivate, getRemoteConfig } from 'firebase/remote-config';

export const app = initializeApp(FIREBASE_API);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const functions = getFunctions(app);
export const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = FIREBASE_API.remoteConfigMinimumFetchIntervalMs;
remoteConfig.defaultConfig = REMOTE_CONFIG_PARAMETER_DEFAULTS;
fetchAndActivate(remoteConfig);

if (config.env === ENVIRONMENTS.LOCAL) {
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
    connectFunctionsEmulator(functions, '127.0.0.1', 5001);
}

if (config.env === ENVIRONMENTS.PRODUCTION || config.env === ENVIRONMENTS.DEVELOPMENT) {
    initializePerformance(app);
}

export const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(FIREBASE_API.appCheckRecaptchaSiteKey),
    isTokenAutoRefreshEnabled: true,
});

export function sendToFirebase({ name, delta, entries }) {
    const metricNameMap = {
        CLS: 'Cumulative Layout Shift',
        LCP: 'Largest Contentful Paint',
        FID: 'First Input Delay',
        TTFB: 'Time To First Byte',
    };

    const startTime = Date.now();
    const value = Math.round(name === 'CLS' ? delta * 1000 : delta);

    const firebasePerformance = getPerformance();

    const performanceTrace = trace(firebasePerformance, metricNameMap[name]);

    // To prevent FirebaseError: only record if value is >0
    if (value > 0) {
        performanceTrace.record(startTime, value, entries);
    }
}
