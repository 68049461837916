import { getRemoteConfig, getString } from 'firebase/remote-config';
import { REMOTE_CONFIG_PARAMETERS, VENDORS } from '@cta-pond/constants';

export default function useMatchingConfig() {
    const remoteConfig = getRemoteConfig();
    const catalistString = getString(remoteConfig, REMOTE_CONFIG_PARAMETERS.CATALIST_MATCHING_FIELDS);
    const targetSmartString = getString(remoteConfig, REMOTE_CONFIG_PARAMETERS.TARGETSMART_MATCHING_FIELDS);

    const copyByVendor = {
        [VENDORS.CATALIST]: JSON.parse(catalistString),
        [VENDORS.TARGETSMART]: JSON.parse(targetSmartString),
    };

    return {
        copyByVendor,
    };
}
