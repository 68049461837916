const ENVIRONMENTS = {
    DEVELOPMENT: 'dev',
    LOCAL: 'local',
    PRODUCTION: 'prod',
};

const GOOGLE_OAUTH_PROVIDER_ID = 'google.com';

const SNACKBAR_VARIANTS = {
    SUCCESS: 'success',
    ERROR: 'error',
    INFO: 'info',
};

const VENDORS = {
    CATALIST: 'CATALIST',
    TARGETSMART: 'TARGETSMART',
    NGPVAN: 'NGPVAN',
    PAD: 'PAD',
};

const VENDOR_NAMES = {
    [VENDORS.CATALIST]: 'Catalist',
    [VENDORS.TARGETSMART]: 'TargetSmart',
    [VENDORS.NGPVAN]: 'NGPVan',
    [VENDORS.PAD]: 'PAD',
};

const VENDOR_SLUGS = {
    [VENDORS.CATALIST]: 'catalist',
    [VENDORS.TARGETSMART]: 'targetsmart',
};

const GCP_WORKFLOW_IDS = {
    VOTERFILE_MATCHING: 'voterfile-matching',
    NGPVAN_RETL: 'ngpvan-bulk-import',
};

/** https://cloud.google.com/workflows/docs/reference/executions/rest/v1/projects.locations.workflows.executions#State */
const GCP_WORKFLOW_EXECUTION_STATUSES = {
    ACTIVE: 'ACTIVE',
    SUCCEEDED: 'SUCCEEDED',
    FAILED: 'FAILED',
    STATE_UNSPECIFIED: 'STATE_UNSPECIFIED',
    QUEUED: 'QUEUED',
    CANCELLED: 'CANCELLED',
    UNAVAILABLE: 'UNAVAILABLE',
};

const GCP_WORFKLOW_EXECUTION_STATUS_LABELS = {
    [GCP_WORKFLOW_EXECUTION_STATUSES.ACTIVE]: 'Active',
    [GCP_WORKFLOW_EXECUTION_STATUSES.SUCCEEDED]: 'Succeeded',
    [GCP_WORKFLOW_EXECUTION_STATUSES.FAILED]: 'Failed',
    [GCP_WORKFLOW_EXECUTION_STATUSES.STATE_UNSPECIFIED]: 'Unknown',
    [GCP_WORKFLOW_EXECUTION_STATUSES.QUEUED]: 'Queued',
    [GCP_WORKFLOW_EXECUTION_STATUSES.CANCELLED]: 'Cancelled',
    [GCP_WORKFLOW_EXECUTION_STATUSES.UNAVAILABLE]: 'Unavailable',
};

const GCP_WORKFLOW_EXECUTION_METADATA_FIELDS = {
    JOB_NAME: 'jobName',
    VENDOR_ID: 'vendorId',
    EXECUTION_ID: 'executionId',
};

const GCP_WORKFLOW_EXECUTION_UI_TABLE_COLUMNS = {
    VENDOR: 'VENDOR',
};

const GCP_WORKFLOW_EXECUTION_MAX_NAME_LENGTH = 100;

const REMOTE_CONFIG_PARAMETERS = {
    VAN_RETL_COPY_AND_CONFIG: 'VANRETLConfig',
    CATALIST_MATCHING_FIELDS: 'catalistMatchingFields',
    TARGETSMART_MATCHING_FIELDS: 'targetSmartMatchingFields',
};

const REMOTE_CONFIG_PARAMETER_DEFAULTS = {
    [REMOTE_CONFIG_PARAMETERS.VAN_RETL_COPY_AND_CONFIG]: '{}',
    [REMOTE_CONFIG_PARAMETERS.CATALIST_MATCHING_FIELDS]: '[]',
    [REMOTE_CONFIG_PARAMETERS.TARGETSMART_MATCHING_FIELDS]: '[]',
};

const SYNC_POINT_TYPES = {
    ACTBLUE: 'ACTBLUE',
    ACTION_NETWORK_REDSHIFT: 'ACTION_NETWORK_REDSHIFT',
    BIG_QUERY: 'BIG_QUERY',
    GOOGLE_ANALYTICS_GA4: 'GOOGLE_ANALYTICS_GA4',
    MOBILE_COMMONS: 'MOBILE_COMMONS',
    MOBILIZE_POSTGRES: 'MOBILIZE_POSTGRES',
    FACEBOOK_PAGE: 'FACEBOOK_PAGE',
    NGPVAN_APP: 'NGPVAN_APP',
    SPOKE_POSTGRES: 'SPOKE_POSTGRES',
};

const SYNC_POINT_VARIANTS = {
    SOURCE: 'SOURCE',
    DESTINATION: 'DESTINATION',
};

const SYNC_POINT_ROUTE_PARAMS = {
    [SYNC_POINT_TYPES.ACTBLUE]: 'actblue',
    [SYNC_POINT_TYPES.ACTION_NETWORK_REDSHIFT]: 'action-network',
    [SYNC_POINT_TYPES.GOOGLE_ANALYTICS_GA4]: 'google-analytics',
    [SYNC_POINT_TYPES.MOBILE_COMMONS]: 'mobile-commons',
    [SYNC_POINT_TYPES.MOBILIZE_POSTGRES]: 'mobilize',
    [SYNC_POINT_TYPES.SPOKE_POSTGRES]: 'spoke',
};

const SYNC_POINT_STATUSES = {
    BUILDING: 'BUILDING', // created in DB but not yet ready for processing
    CONNECTED: 'CONNECTED', // processing complete & succeesful
    ERROR: 'ERROR', // processing failed
    PROCESSING: 'PROCESSING', // processing in progress, e.g. new source being created in airbyte
};

const SYNC_POINT_ICON_IMAGE_FILES = {
    [SYNC_POINT_TYPES.ACTBLUE]: 'actblue_icon.png',
    [SYNC_POINT_TYPES.ACTION_NETWORK_REDSHIFT]: 'action_network_icon.png',
    [SYNC_POINT_TYPES.BIG_QUERY]: 'big_query_icon.png',
    [SYNC_POINT_TYPES.GOOGLE_ANALYTICS_GA4]: 'google_analytics_icon.png',
    [SYNC_POINT_TYPES.MOBILE_COMMONS]: 'mobile_commons_icon.png',
    [SYNC_POINT_TYPES.MOBILIZE_POSTGRES]: 'mobilize_icon.png',
    [SYNC_POINT_TYPES.SPOKE_POSTGRES]: 'spoke_icon.png',
};

const SYNC_STATUSES = {
    ACTIVE: 'ACTIVE',
    DRAFT: 'DRAFT',
    ERROR: 'ERROR',
    INACTIVE: 'INACTIVE',
    QUEUED: 'QUEUED',
    PROCESSING: 'PROCESSING',
};

const SYNC_STATUS_LABELS = {
    [SYNC_STATUSES.ACTIVE]: 'Active',
    [SYNC_STATUSES.DRAFT]: 'Draft',
    [SYNC_STATUSES.ERROR]: 'Error',
    [SYNC_STATUSES.INACTIVE]: 'Inactive',
    [SYNC_STATUSES.QUEUED]: 'Queued',
    [SYNC_STATUSES.PROCESSING]: 'Processing',
};

const SYNC_FREQUENCIES = {
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY',
};

const SYNC_FREQUENCY_LABELS = {
    [SYNC_FREQUENCIES.DAILY]: 'Daily',
    [SYNC_FREQUENCIES.WEEKLY]: 'Weekly',
    [SYNC_FREQUENCIES.MONTHLY]: 'Monthly',
};

const SYNC_RUN_STATUSES = {
    SUCCEEDED: 'SUCCEEDED',
    FAILED: 'FAILED',
    RUNNING: 'RUNNING',
};

const SYNC_RUN_STATUS_LABELS = {
    [SYNC_RUN_STATUSES.SUCCEEDED]: 'Succeeded',
    [SYNC_RUN_STATUSES.FAILED]: 'Failed',
    [SYNC_RUN_STATUSES.RUNNING]: 'Running',
};

const DEFAULT_SOURCE_TYPES = [
    SYNC_POINT_TYPES.ACTBLUE,
    // Commented as per https://techallies.atlassian.net/browse/PAD-2457
    // We won't be supporting Action Network source type for launch
    // SYNC_POINT_TYPES.ACTION_NETWORK_REDSHIFT,
    SYNC_POINT_TYPES.GOOGLE_ANALYTICS_GA4,
    SYNC_POINT_TYPES.MOBILE_COMMONS,
    SYNC_POINT_TYPES.MOBILIZE_POSTGRES,
    SYNC_POINT_TYPES.SPOKE_POSTGRES,
];

const PROJECT_LIST_QUERY_PARAMS = {
    PAGE_SIZE: 50,
    FILTER_STRING: 'labels.type:partner labels.location:us state:ACTIVE',
};

const USER_ROLES = {
    ADMIN: 'ADMIN',
    EDITOR: 'EDITOR',
    CONTRIBUTOR: 'CONTRIBUTOR',
    VIEWER: 'VIEWER',
};

const USER_ROLE_LABELS = {
    [USER_ROLES.ADMIN]: 'Admin',
    [USER_ROLES.EDITOR]: 'Editor',
    [USER_ROLES.CONTRIBUTOR]: 'Contributor',
    [USER_ROLES.VIEWER]: 'Viewer',
};

/**
 * Flags with explicit values in the datastore
 */
const DEFINED_PROJECT_FEATURE_FLAGS = {
    IS_CATALIST_MATCHING_ENABLED: 'isCatalistMatchingEnabled',
    IS_TARGETSMART_MATCHING_ENABLED: 'isTargetSmartMatchingEnabled',
    IS_VAN_RETL_ENABLED: 'isVANRETLEnabled',
    IS_RESEARCH_SUITE_ENABLED: 'isResearchSuiteEnabled',
    IS_HOPPER_SYNCS_PAGE_ENABLED: 'isHopperSyncsPageEnabled',
    IS_HOPPER_SOURCES_PAGE_ENABLED: 'isHopperSourcesPageEnabled',
};

/**
 * Flags derived from defined flags, not stored in the datastore
 */
const DERIVED_PROJECT_FEATURE_FLAGS = {
    IS_MATCHING_ENABLED: 'isMatchingEnabled',
    ARE_BOTH_MATCHING_PROVIDERS_ENABLED: 'areBothMatchingProvidersEnabled',
    MATCHING_EXECUTIONS_ENDPOINT: 'matchingExecutionsEndpoint',
    VAN_RETL_EXECUTIONS_ENDPOINT: 'vanRETLExecutionsEndpoint',
};

const PROJECT_FEATURE_FLAGS = {
    ...DEFINED_PROJECT_FEATURE_FLAGS,
    ...DERIVED_PROJECT_FEATURE_FLAGS,
};

/**
 * Super users will have these flags enabled by default, regardless of current project role
 */
const DEFAULT_FEATURE_FLAGS_FOR_SUPER_USERS = {
    [DEFINED_PROJECT_FEATURE_FLAGS.IS_CATALIST_MATCHING_ENABLED]: true,
    [DEFINED_PROJECT_FEATURE_FLAGS.IS_TARGETSMART_MATCHING_ENABLED]: true,
    [DEFINED_PROJECT_FEATURE_FLAGS.IS_VAN_RETL_ENABLED]: true,
    [DEFINED_PROJECT_FEATURE_FLAGS.IS_RESEARCH_SUITE_ENABLED]: true,
    [DEFINED_PROJECT_FEATURE_FLAGS.IS_HOPPER_SYNCS_PAGE_ENABLED]: true,
};

const FIRESTORE_COLLECTIONS = {
    PROJECTS: 'projects',
    USERS: 'users',
    CREDENTIALS: 'credentials',
    SYNCS: 'syncs',
    SOURCES: 'sources',
    DESTINATIONS: 'destinations',
    UNIVERSAL_DESTINATIONS: 'universalDestinations',
    SYNC_POINT_CONFIGURATIONS: 'syncPointConfigurations',
    PRIVATE: 'private',
};

const SYNC_PLATFORMS = {
    AIRBYTE: 'AIRBYTE',
};

export {
    DEFAULT_FEATURE_FLAGS_FOR_SUPER_USERS,
    DEFAULT_SOURCE_TYPES,
    DEFINED_PROJECT_FEATURE_FLAGS,
    DERIVED_PROJECT_FEATURE_FLAGS,
    ENVIRONMENTS,
    FIRESTORE_COLLECTIONS,
    GCP_WORFKLOW_EXECUTION_STATUS_LABELS,
    GCP_WORKFLOW_EXECUTION_MAX_NAME_LENGTH,
    GCP_WORKFLOW_EXECUTION_METADATA_FIELDS,
    GCP_WORKFLOW_EXECUTION_STATUSES,
    GCP_WORKFLOW_EXECUTION_UI_TABLE_COLUMNS,
    GCP_WORKFLOW_IDS,
    GOOGLE_OAUTH_PROVIDER_ID,
    PROJECT_FEATURE_FLAGS,
    PROJECT_LIST_QUERY_PARAMS,
    REMOTE_CONFIG_PARAMETERS,
    REMOTE_CONFIG_PARAMETER_DEFAULTS,
    SNACKBAR_VARIANTS,
    SYNC_FREQUENCIES,
    SYNC_FREQUENCY_LABELS,
    SYNC_PLATFORMS,
    SYNC_POINT_ICON_IMAGE_FILES,
    SYNC_POINT_ROUTE_PARAMS,
    SYNC_POINT_STATUSES,
    SYNC_POINT_TYPES,
    SYNC_POINT_VARIANTS,
    SYNC_RUN_STATUSES,
    SYNC_RUN_STATUS_LABELS,
    SYNC_STATUSES,
    SYNC_STATUS_LABELS,
    USER_ROLES,
    USER_ROLE_LABELS,
    VENDORS,
    VENDOR_NAMES,
    VENDOR_SLUGS,
};
