import { Box, Typography } from '@mui/material';
import NaturePeopleOutlinedIcon from '@mui/icons-material/NaturePeopleOutlined';

export default function WelcomePage() {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pt: 4 }}>
            <NaturePeopleOutlinedIcon sx={{ fontSize: 100, color: 'primary', mb: 2 }} />
            <Typography variant="h2" gutterBottom>
                Welcome to PAD UI
            </Typography>
            <Typography variant="body1" align="center" gutterBottom>
                To access all the features of this app, please contact our support team using the help button below.
            </Typography>
        </Box>
    );
}
