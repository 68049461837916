// Third party
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { browserTracingIntegration, replayIntegration } from '@sentry/browser';
import { ENVIRONMENTS } from '@cta-pond/constants';

// Project imports
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import { sendToFirebase } from './firebase';
import config, { SENTRY, ZENDESK } from 'config';
import loadScript from 'utils/loadScript';
import configureAxios from 'configureAxios';

// Style + assets
import 'assets/scss/style.scss';

if (config.env !== ENVIRONMENTS.LOCAL) {
    Sentry.init({
        release: SENTRY.releaseId,
        autoSessionTracking: true,
        dsn: SENTRY.dsn,
        environment: config.env,
        integrations: [
            browserTracingIntegration({
                tracePropagationTargets: SENTRY.tracePropagationTargets.split(',') || [],
            }),
            replayIntegration(),
        ],
        sampleRate: SENTRY.sampleRate, // Scale from 0 to 1.0 (100% of error events)
        tracesSampleRate: SENTRY.tracesSampleRate, // Scale from 0 to 1.0 (100% of transactions)
        replaysSessionSampleRate: SENTRY.replaysSessionSampleRate, // Scale from 0 to 1.0 (100% of sessions)
        replaysOnErrorSampleRate: SENTRY.replaysOnErrorSampleRate, // Scale from 0 to 1.0 (100% of errors)
    });

    reportWebVitals(sendToFirebase);

    // Load zendesk widget
    loadScript({
        id: 'ze-snippet',
        src: `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK.apiKey}`,
    });
}

configureAxios();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <StrictMode>
        <App />
    </StrictMode>,
);
