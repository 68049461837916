import { Box, Button, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
    GCP_WORKFLOW_EXECUTION_METADATA_FIELDS,
    GCP_WORKFLOW_IDS,
    PROJECT_FEATURE_FLAGS,
    SNACKBAR_VARIANTS,
    VENDORS,
    VENDOR_NAMES,
    VENDOR_SLUGS,
} from '@cta-pond/constants';
import BigQueryTableSelector from 'components/BigQueryTableSelector';
import FieldMatcher from 'components/FieldMatcher';
import InlineMessage from 'components/InlineMessage';
import PageHeader from 'components/PageHeader';
import SyncNameInput from 'components/SyncNameInput';
import SyncWizard from 'components/SyncWizard';
import SyncWizardFooterWrapper from 'components/SyncWizardFooterWrapper';
import { getAuthedFetchConfig } from 'helpers/auth';
import useAsync from 'hooks/useAsync';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useMatchingConfig from 'hooks/useMatchingConfig';

export default function NewMatchingJobPage() {
    const [jobName, setJobName] = useState('');
    const [isJobNameValid, setIsJobNameValid] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [sourceDataset, setSourceDataset] = useState(null);
    const [sourceTable, setSourceTable] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [didUserClickNext, setDidUserClickNext] = useState(false);
    const [didUserClickSubmit, setDidUserClickSubmit] = useState(false);
    const [matchedFields, setMatchedFields] = useState({});

    const { copyByVendor } = useMatchingConfig();
    const featureFlags = useFeatureFlags();
    const { projectId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const areBothMatchingProvidersEnabled = featureFlags[PROJECT_FEATURE_FLAGS.ARE_BOTH_MATCHING_PROVIDERS_ENABLED];

    let subtitle = 'Match your data to Catalist voter file data';
    let step1Label = 'Select data source';
    let step2Label = 'Match columns to Catalist fields';

    if (featureFlags[PROJECT_FEATURE_FLAGS.IS_TARGETSMART_MATCHING_ENABLED]) {
        subtitle = 'Match your data to TargetSmart voter file data';
        step2Label = 'Match columns to TargetSmart fields';
    }

    if (areBothMatchingProvidersEnabled) {
        subtitle = 'Match your data to voter file data from Catalist and TargetSmart';
        step1Label = 'Select data source & provider';
        step2Label = 'Match columns to provider fields';
    }

    const isFirstPageValid = isJobNameValid && Boolean(sourceDataset) && Boolean(sourceTable);

    const requiredProviderFields = useMemo(
        () => copyByVendor[selectedProvider]?.visibleFields || [],
        [copyByVendor, selectedProvider],
    );

    const areAllRequiredFieldsMatched = useMemo(
        () => requiredProviderFields.every(({ header, isRequired }) => !isRequired || Boolean(matchedFields[header])),
        [requiredProviderFields, matchedFields],
    );

    const columnMatchingHelperText = copyByVendor[selectedProvider]?.columnMatchingHelperText;

    const handleNext = () => {
        setDidUserClickNext(true);

        if (!isFirstPageValid) {
            return;
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSubmit = () => {
        setDidUserClickSubmit(true);

        if (!areAllRequiredFieldsMatched) {
            return;
        }

        requestToSubmitNewMatchingExecution.perform();
    };

    const handleJobNameChange = (value) => {
        setJobName(value);
    };

    const handleIsJobNameValidChange = (value) => {
        setIsJobNameValid(value);
    };

    const handleSelectDataset = (event, option) => {
        setSourceTable(null);
        setSourceDataset(option ?? null);
    };

    const handleSelectTable = (event, option) => {
        setSourceTable(option ?? null);
    };

    const resetStepTwo = () => {
        setDidUserClickSubmit(false);
        setMatchedFields({});
    };

    /**
     * Reset the matching fields state when the user changes providers
     */
    const handleChangeProvider = (event, newProvider) => {
        setSelectedProvider(newProvider);
        resetStepTwo();
    };

    useEffect(() => {
        let initialSelectedProvider = VENDORS.CATALIST;

        if (
            featureFlags[PROJECT_FEATURE_FLAGS.IS_TARGETSMART_MATCHING_ENABLED] &&
            !featureFlags[PROJECT_FEATURE_FLAGS.IS_CATALIST_MATCHING_ENABLED]
        ) {
            initialSelectedProvider = VENDORS.TARGETSMART;
        }

        setSelectedProvider(initialSelectedProvider);
    }, [featureFlags]);

    useEffect(() => {
        requestToFetchAvailableColumns.perform();
    }, [sourceTable]);

    const requestToFetchAvailableColumns = useAsync(async () => {
        if (!sourceTable) {
            return [];
        }

        const authedRequestConfig = await getAuthedFetchConfig();

        const { data = [] } = await axios.get(
            `/api/big-query-schema?finder=columnsInTable&projectId=${projectId}&datasetId=${sourceDataset}&tableId=${sourceTable}`,
            authedRequestConfig,
        );

        return data.map(({ name }) => name);
    });

    const requestToSubmitNewMatchingExecution = useAsync(async () => {
        const authedRequestConfig = await getAuthedFetchConfig();

        const fields = Object.entries(matchedFields)
            .filter(([_, mappedColumn]) => Boolean(mappedColumn))
            .map(([fieldName, mappedColumn]) => ({ fieldName, mappedColumn }));

        const { data = {} } = await axios.post(
            '/api/gcp-workflow-executions',
            {
                fields,
                project_id: projectId,
                workflow_id: GCP_WORKFLOW_IDS.VOTERFILE_MATCHING,
                job_name: jobName,
                source_dataset: sourceDataset,
                source_table: sourceTable,
                vendor: VENDOR_SLUGS[selectedProvider],
                metadata: {
                    [GCP_WORKFLOW_EXECUTION_METADATA_FIELDS.VENDOR_ID]: selectedProvider,
                },
            },
            authedRequestConfig,
        );

        enqueueSnackbar(`Workflow execution created successfully`, {
            variant: SNACKBAR_VARIANTS.SUCCESS,
        });
        navigate(`/projects/${projectId}/matching-jobs`);

        return data;
    });

    const datasetsFilter = (datasets) => {
        return datasets.filter(
            (dataset) => !dataset.toLowerCase().includes('targetsmart') && !dataset.toLowerCase().includes('catalist'),
        );
    };

    const Step1 = (
        <>
            <InlineMessage
                type="info"
                message="Matching jobs are currently limited to 1GB of total data. A submitted job exceeding 1GB will fail and return an error."
                sxOverrides={{ mb: 2 }}
            />
            <SyncNameInput
                syncName={jobName}
                onSyncNameChange={handleJobNameChange}
                isSyncNameValid={isJobNameValid}
                onIsSyncNameValidChange={handleIsJobNameValidChange}
                showAllErrors={didUserClickNext}
            />
            <BigQueryTableSelector
                projectId={projectId}
                sourceDataset={sourceDataset}
                datasetsFilter={datasetsFilter}
                onSelectDataset={handleSelectDataset}
                sourceTable={sourceTable}
                onSelectTable={handleSelectTable}
                showAllErrors={didUserClickNext}
            />
            {areBothMatchingProvidersEnabled && (
                <>
                    <Typography variant="h4" sx={{ mb: 2 }}>
                        Choose Provider
                    </Typography>
                    <ToggleButtonGroup
                        fullWidth={true}
                        sx={{ mb: 3 }}
                        color="primary"
                        value={selectedProvider}
                        exclusive
                        onChange={handleChangeProvider}
                        aria-label="Provider"
                    >
                        <ToggleButton value={VENDORS.CATALIST}>Catalist</ToggleButton>
                        <ToggleButton value={VENDORS.TARGETSMART}>TargetSmart</ToggleButton>
                    </ToggleButtonGroup>
                </>
            )}
            <SyncWizardFooterWrapper sxOverrides={{ justifyContent: 'end' }}>
                <Button variant="outlined" onClick={handleNext}>
                    Next: Match Columns
                </Button>
            </SyncWizardFooterWrapper>
        </>
    );

    const Step2 = (
        <>
            <Box>
                <Typography variant="h4" sx={{ mb: 1 }}>
                    Match columns
                </Typography>
                <Typography variant="subtitle2" sx={{ mb: 2 }}>
                    Select columns from <code>{sourceTable ?? 'table'}</code> to match to each required{' '}
                    {VENDOR_NAMES[selectedProvider]} field
                </Typography>
                {columnMatchingHelperText && (
                    <InlineMessage type="info" message={columnMatchingHelperText} sxOverrides={{ mb: 2 }} />
                )}
                <FieldMatcher
                    allFields={requiredProviderFields}
                    matchedFields={matchedFields}
                    matchOptions={requestToFetchAvailableColumns.result || []}
                    fieldValueKey="header"
                    headerLabels={['Field', `${VENDOR_NAMES[selectedProvider]} header`, 'Column']}
                    onMatchChange={(fieldValue, newMatch) =>
                        setMatchedFields({ ...matchedFields, [fieldValue]: newMatch })
                    }
                    areMatchOptionsLoading={requestToFetchAvailableColumns.isRunning}
                    showAllErrors={didUserClickSubmit}
                />
            </Box>
            <SyncWizardFooterWrapper sxOverrides={{ justifyContent: 'between' }}>
                {requestToSubmitNewMatchingExecution.didFail && (
                    <InlineMessage
                        type="error"
                        message={requestToSubmitNewMatchingExecution.error.message}
                        sx={{ justifySelf: 'start' }}
                    />
                )}
                <Button color="inherit" onClick={handleBack} sx={{ mr: 1, ml: 'auto' }}>
                    Back
                </Button>
                <Button variant="contained" onClick={handleSubmit}>
                    {`Submit ${VENDOR_NAMES[selectedProvider]} Matching Job`}
                </Button>
            </SyncWizardFooterWrapper>
        </>
    );

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <PageHeader title="Create a new voter file matching job" subtitle={subtitle} />
            <SyncWizard
                steps={[
                    {
                        label: step1Label,
                        content: Step1,
                    },
                    {
                        label: step2Label,
                        content: Step2,
                    },
                ]}
                activeStepIndex={activeStep}
                isLoading={requestToSubmitNewMatchingExecution.isRunning}
                loadingMessage={'Submitting matching job...'}
                loadingBackdropTestId={'submit-matching-job-backdrop'}
            />
        </Box>
    );
}
