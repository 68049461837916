import { Autocomplete, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import { SYNC_POINT_TYPES } from '@cta-pond/constants';
import SyncPointLogo from 'components/SyncPointLogo';
import { useRouteLoaderData } from 'react-router-dom';

export default function ControlledSyncPointAutocomplete({
    name,
    label,
    helperText = ' ',
    control,
    options = [],
    onChange = () => {},
}) {
    ControlledSyncPointAutocomplete.propTypes = {
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        control: PropTypes.object.isRequired,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                type: PropTypes.oneOf(Object.values(SYNC_POINT_TYPES)).isRequired,
            }),
        ),
        helperText: PropTypes.string,
        onChange: PropTypes.func,
    };

    const { syncPointConfigurations } = useRouteLoaderData('projectRoot');

    return (
        <Controller
            rules={{ required: true }}
            name={name}
            control={control}
            defaultValue={null}
            render={({ field, formState: { errors } }) => (
                <Autocomplete
                    {...field}
                    onChange={(_, data) => {
                        field.onChange(data);
                        onChange?.(data);
                    }}
                    fullWidth={true}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.name}
                    groupBy={(option) => syncPointConfigurations[option.type]?.pluralName ?? 'Other'}
                    renderOption={({ key, ...rest }, { name, type }) => (
                        <SyncPointLogo pointType={type} key={key} {...rest}>
                            <Typography variant="h5">{name}</Typography>
                        </SyncPointLogo>
                    )}
                    options={options}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required={true}
                            label={label}
                            helperText={helperText ?? ' '}
                            error={Boolean(errors?.[name])}
                        />
                    )}
                />
            )}
        />
    );
}
