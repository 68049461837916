import { Alert, Box, Button, TextField } from '@mui/material';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';
import { useNavigate, useRouteLoaderData, useParams, useSearchParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { addDoc, collection } from 'firebase/firestore/lite';
import { useSnackbar } from 'notistack';
import { FIRESTORE_COLLECTIONS, SYNC_STATUSES, SNACKBAR_VARIANTS, SYNC_FREQUENCIES } from '@cta-pond/constants';

import { firestore } from '../firebase';

import AbsoluteBackdrop from 'components/AbsoluteBackdrop';
import NewSourceDropdown from 'components/NewSourceDropdown';
import EmptyStatePlaceholder from 'components/EmptyStatePlaceholder';
import ControlledSyncPointAutocomplete from 'components/ControlledSyncPointAutocomplete';

export default function NewSyncStepOneForm() {
    const { sources, destinations } = useRouteLoaderData('syncRoot');
    const { projectId } = useParams();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [searchParams] = useSearchParams();
    const defaultSource = sources?.find(({ id }) => id === searchParams?.get('sourceId')) ?? null;
    const maybeSourceDefault = defaultSource ? { source: defaultSource } : {};

    const { handleSubmit, getValues, setError, resetField, control, formState } = useForm({
        mode: 'onTouched',
        defaultValues: maybeSourceDefault,
    });

    const { errors, isSubmitting } = formState;

    const doesProjectHaveSources = sources?.length;
    const isSourceSelected = Boolean(getValues('source'));

    // TODO: join with project's saved destinations, use selected source to filter
    const eligibleDestinations = isSourceSelected ? destinations : [];
    const formTitle = 'New sync initial setup';

    const syncNameRules = {
        required: true,
        minLength: 2,
        maxLength: 250,
    };

    const serverError = errors?.root?.serverError;

    const createSync = async (syncPayload) => {
        const { syncName, source, destination } = syncPayload;

        // TODO: add these using an `onDocumentCreate` cloud function
        const defaultSyncFields = {
            status: SYNC_STATUSES.QUEUED,
            frequency: SYNC_FREQUENCIES.DAILY,
            syncRuns: [],
        };

        try {
            await addDoc(
                collection(firestore, FIRESTORE_COLLECTIONS.PROJECTS, projectId, FIRESTORE_COLLECTIONS.SYNCS),
                {
                    ...defaultSyncFields,
                    name: syncName,
                    // TODO: add an actual firestore doc reference, e.g. `doc(firestore, source.path)`
                    sourceDocPath: source.path,
                    destinationDocPath: destination.path,
                },
            );

            enqueueSnackbar(`Sync '${syncName}' successfully created`, { variant: SNACKBAR_VARIANTS.SUCCESS });
            navigate(`/projects/${projectId}/syncs`);
        } catch (error) {
            setError('root.serverError', { message: 'Oops! Something went wrong. Please try again.' });
        }
    };

    return doesProjectHaveSources ? (
        <>
            <AbsoluteBackdrop isLoading={isSubmitting} loadingMessage="Creating sync..." />
            <form autoComplete="off" name={formTitle} aria-label={formTitle} onSubmit={handleSubmit(createSync)}>
                <Controller
                    name="syncName"
                    rules={syncNameRules}
                    control={control}
                    defaultValue={''}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            inputProps={syncNameRules}
                            label={'Sync name'}
                            required={syncNameRules.required}
                            error={Boolean(errors?.syncName)}
                            sx={{ mb: 4, width: '100%' }}
                        />
                    )}
                />
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'stretch', mb: 2 }}>
                    <ControlledSyncPointAutocomplete
                        name="source"
                        label={'Choose a source'}
                        options={sources}
                        control={control}
                        onChange={() => resetField('destination')}
                    />
                    <ArrowForwardSharpIcon sx={{ mx: 2, mb: 3 }} />
                    <ControlledSyncPointAutocomplete
                        name="destination"
                        label={'Choose a destination'}
                        options={eligibleDestinations}
                        control={control}
                        helperText={isSourceSelected ? ' ' : 'Select a source first'}
                    />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
                    <Button variant="text" onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                    <Button type="submit" variant="contained">
                        Create sync
                    </Button>
                </Box>
                {serverError && (
                    <Alert square sx={{ mt: 2 }} severity="error">
                        {serverError.message}
                    </Alert>
                )}
            </form>
        </>
    ) : (
        <EmptyStatePlaceholder
            title={'No sources yet'}
            subtitle={'You need to add a source before you can create a sync.'}
            sx={{ mb: 2 }}
        >
            <NewSourceDropdown />
        </EmptyStatePlaceholder>
    );
}
