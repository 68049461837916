import PropTypes from 'prop-types';
import { Chip } from '@mui/material';

import { USER_ROLES, USER_ROLE_LABELS } from '@cta-pond/constants';

export default function ProjectRoleLabel({ projectRoles }) {
    ProjectRoleLabel.propTypes = {
        projectRoles: PropTypes.object.isRequired,
    };

    const mostPriviledgedProjectRole = [
        USER_ROLES.ADMIN,
        USER_ROLES.EDITOR,
        USER_ROLES.CONTRIBUTOR,
        USER_ROLES.VIEWER,
    ].find((role) => projectRoles?.[role]);
    const activeProjectRoleLabel = USER_ROLE_LABELS[mostPriviledgedProjectRole];

    return <Chip sx={{ ml: 1 }} label={activeProjectRoleLabel} size="small" />;
}
