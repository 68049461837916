import PendingActionsSharpIcon from '@mui/icons-material/PendingActionsSharp';
import { Box, Paper, Typography } from '@mui/material';
import PropTypes from 'prop-types';

export default function EmptyStatePlaceholder({
    title = 'Nothing to show',
    subtitle = null,
    children = null,
    sx = {},
}) {
    EmptyStatePlaceholder.propTypes = {
        title: PropTypes.string,
        subtitle: PropTypes.string,
        children: PropTypes.node,
        sx: PropTypes.object,
    };

    return (
        <Box component={Paper} variant="outlined" square sx={{ color: 'grey.800', p: 4, textAlign: 'center', ...sx }}>
            <PendingActionsSharpIcon sx={{ fontSize: 72, mb: 2, color: 'inherit' }} />
            <Typography variant="h4" sx={{ mb: 1, color: 'inherit' }}>
                {title}
            </Typography>
            {subtitle && (
                <Typography variant="subtitle2" sx={{ color: 'inherit' }}>
                    {subtitle}
                </Typography>
            )}
            {children && <Box sx={{ mt: 2 }}>{children}</Box>}
        </Box>
    );
}
