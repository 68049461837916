import {
    FIRESTORE_COLLECTIONS,
    SNACKBAR_VARIANTS,
    SYNC_POINT_ROUTE_PARAMS,
    SYNC_POINT_STATUSES,
} from '@cta-pond/constants';
import { Alert, Box, Button, Paper, Typography } from '@mui/material';
import AbsoluteBackdrop from 'components/AbsoluteBackdrop';
import SyncPointInput from 'components/SyncPointInput';
import SyncPointLogo from 'components/SyncPointLogo';
import { addDoc, collection, deleteDoc, updateDoc } from 'firebase/firestore/lite';
import { httpsCallable } from 'firebase/functions';
import useSyncPointMetadata from 'hooks/useSyncPointMetadata';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { firestore, functions } from '../firebase';

function lookupSyncPointTypeFromRouteParam(routeParam) {
    if (!routeParam) {
        throw new Error('routeParam is required');
    }

    const [syncPointType] = Object.entries(SYNC_POINT_ROUTE_PARAMS).find(([_, value]) => value === routeParam) ?? [];

    return syncPointType ?? null;
}

export default function NewSourcePage() {
    const params = useParams();
    const navigate = useNavigate();
    const {
        control,
        handleSubmit,
        setError,
        formState: { errors, isSubmitting },
    } = useForm({ mode: 'onTouched' });
    const { enqueueSnackbar } = useSnackbar();

    const sourceType = lookupSyncPointTypeFromRouteParam(params.sourceType);

    if (!sourceType) {
        throw new Error('No matching source type found for sourceType url param');
    }

    const { singularName, inputs } = useSyncPointMetadata(sourceType);
    const title = `Connect a new ${singularName} source`;

    if (!inputs?.length) {
        throw new Error(`No inputs configured for ${sourceType}`);
    }

    const serverError = errors?.root?.serverError;

    const createSource = async (sourcePayload) => {
        const { name, ...allOtherInputs } = sourcePayload;
        const allOtherInputsEntries = Object.entries(allOtherInputs);

        if (!name || !allOtherInputsEntries.length) {
            throw Error('Missing form inputs');
        }

        const { secrets, config } = allOtherInputsEntries.reduce(
            (acc, [name, { value, isSecret }]) => {
                if (isSecret) {
                    return { ...acc, secrets: [...acc.secrets, { name, value }] };
                }

                return { ...acc, config: { ...acc.config, [name]: value } };
            },
            { secrets: [], config: {} },
        );

        let newSourceDocRef;

        try {
            newSourceDocRef = await addDoc(
                collection(firestore, FIRESTORE_COLLECTIONS.PROJECTS, params.projectId, FIRESTORE_COLLECTIONS.SOURCES),
                {
                    name: name.value,
                    config,
                    type: sourceType,
                    status: SYNC_POINT_STATUSES.BUILDING,
                },
            );

            const createSecretsForSource = httpsCallable(functions, 'createSecretsForSource');

            const createSecretsResponse = await createSecretsForSource({
                secrets,
                sourceId: newSourceDocRef.id,
                projectId: params.projectId,
            });

            await updateDoc(newSourceDocRef, {
                secretReferences: createSecretsResponse?.data?.secretReferences ?? {},
                status: SYNC_POINT_STATUSES.PROCESSING,
            });

            enqueueSnackbar(`Successfully connected a new ${singularName} source`, {
                variant: SNACKBAR_VARIANTS.SUCCESS,
            });
            navigate(-1);
        } catch (error) {
            setError('root.serverError', { message: 'Oops! Something went wrong. Please try again.' });
            if (newSourceDocRef) await deleteDoc(newSourceDocRef);
        }
    };

    return (
        <Paper component={'section'} square elevation={1} sx={{ p: 4, mx: 12, position: 'relative' }}>
            <AbsoluteBackdrop isLoading={isSubmitting ?? false} loadingMessage="Connecting source..." />
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <SyncPointLogo pointType={sourceType} />
                <Typography variant="h3" sx={{ ml: 2 }}>
                    {title}
                </Typography>
            </Box>
            <form autoComplete="off" name={title} aria-label={title} onSubmit={handleSubmit(createSource)}>
                {/* name input is first and applies to all source types */}
                <SyncPointInput
                    input={{
                        name: 'name',
                        label: 'Source name',
                        helperText: 'A memorable name for this source',
                    }}
                    control={control}
                />
                {inputs.map((input) => (
                    <SyncPointInput key={`new-source-form-input-${input.name}`} input={input} control={control} />
                ))}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
                    <Button variant="text" onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                    <Button type="submit" variant="contained">
                        Connect
                    </Button>
                </Box>
                {serverError && (
                    <Alert square sx={{ mt: 2 }} severity="error">
                        {serverError.message}
                    </Alert>
                )}
            </form>
        </Paper>
    );
}
