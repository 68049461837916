import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined';
import DatasetLinked from '@mui/icons-material/DatasetLinked';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import JoinInnerOutlinedIcon from '@mui/icons-material/JoinInnerOutlined';
import LaunchIcon from '@mui/icons-material/Launch';
import OutputOutlinedIcon from '@mui/icons-material/OutputOutlined';
import {
    Box,
    Button,
    Divider,
    Drawer,
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import { NavLink, useLocation, useNavigate, useParams, useRouteLoaderData } from 'react-router-dom';

import { PROJECT_FEATURE_FLAGS } from '@cta-pond/constants';

import ProjectRoleLabel from 'components/ProjectRoleLabel';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useLayoutState from 'hooks/useLayoutState';
import { drawerWidth } from 'themes/constants';

export default memo(Sidebar);

function Sidebar({ window = null }) {
    Sidebar.propTypes = {
        window: PropTypes.object,
    };

    const theme = useTheme();
    const { isSidebarDrawerOpen, toggleIsSidebarDrawerOpen, setIsSidebarDrawerOpen } = useLayoutState();

    const { loggedInUser } = useRouteLoaderData('projects');
    const { allProjectsForUser, activeProjectDetails } = useRouteLoaderData('projectRoot');
    const featureFlags = useFeatureFlags();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const { projectId } = useParams();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const isProjectMenuOpen = Boolean(anchorEl);

    const container = window ? () => window.document.body : undefined;
    const isSuperUser = loggedInUser?.claims?.isSuperUser;

    const { roles, displayName } = activeProjectDetails;
    const defaultProjectNameText = 'No project name';

    const maybeMatchingNavItem =
        featureFlags?.[PROJECT_FEATURE_FLAGS.IS_MATCHING_ENABLED] || isSuperUser
            ? [{ to: `/projects/${projectId}/matching-jobs`, label: 'Matching Jobs', icon: <JoinInnerOutlinedIcon /> }]
            : [];
    const maybeRETLNavItem =
        featureFlags?.[PROJECT_FEATURE_FLAGS.IS_VAN_RETL_ENABLED] || isSuperUser
            ? [{ to: `/projects/${projectId}/retl-jobs`, label: 'rETL Jobs', icon: <CloudSyncOutlinedIcon /> }]
            : [];
    const maybeSyncsNavItem =
        featureFlags?.[PROJECT_FEATURE_FLAGS.IS_HOPPER_SYNCS_PAGE_ENABLED] || isSuperUser
            ? [{ to: `/projects/${projectId}/syncs`, label: 'Syncs', icon: <HubOutlinedIcon /> }]
            : [];
    const maybeSourcesNavItem =
        featureFlags?.[PROJECT_FEATURE_FLAGS.IS_HOPPER_SOURCES_PAGE_ENABLED] || isSuperUser
            ? [{ to: `/projects/${projectId}/sources`, label: 'Sources', icon: <OutputOutlinedIcon /> }]
            : [];

    const visibleNavItems = [
        ...maybeSyncsNavItem,
        ...maybeSourcesNavItem,
        ...maybeMatchingNavItem,
        ...maybeRETLNavItem,
    ];

    const activeLinkStyles = {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.main,
    };

    const onClickMenuItem = () => {
        if (!matchUpMd) setIsSidebarDrawerOpen(false);
    };

    const onClickSwitchProject = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onClickDifferentProject = (newProjectId) => {
        setAnchorEl(null);
        const targetRoute = pathname?.replace(projectId, newProjectId);

        if (targetRoute) {
            navigate(targetRoute);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const navList = visibleNavItems?.length ? (
        <List>
            {visibleNavItems?.map((visibleRoute, idx) => (
                <ListItem key={idx}>
                    <ListItemButton
                        component={NavLink}
                        to={visibleRoute.to}
                        style={({ isActive }) => (isActive ? activeLinkStyles : undefined)}
                        onClick={onClickMenuItem}
                    >
                        <ListItemIcon sx={{ my: 'auto', color: 'inherit', minWidth: !visibleRoute?.icon ? 18 : 36 }}>
                            {visibleRoute.icon}
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography variant={'h5'} color="inherit">
                                    {visibleRoute.label}
                                </Typography>
                            }
                        />
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    ) : null;

    return (
        <Box
            component="nav"
            sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }}
            aria-label="Primary navigation"
        >
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={isSidebarDrawerOpen}
                onClose={() => toggleIsSidebarDrawerOpen()}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        [theme.breakpoints.up('md')]: {
                            top: '75px',
                        },
                        [theme.breakpoints.down('md')]: {
                            paddingTop: '20px',
                        },
                    },
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1, ml: 1 }}>
                    <Typography variant="h5">ACTIVE PROJECT</Typography>
                    {allProjectsForUser.length > 1 && (
                        <>
                            <Button
                                id="basic-button"
                                aria-controls={isProjectMenuOpen ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={isProjectMenuOpen ? 'true' : undefined}
                                onClick={onClickSwitchProject}
                            >
                                Switch Project
                            </Button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={isProjectMenuOpen}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                {allProjectsForUser
                                    .filter((project) => project.id !== projectId)
                                    .map(({ id, displayName, roles }) => (
                                        <MenuItem key={id} onClick={() => onClickDifferentProject(id)}>
                                            <ListItemIcon>
                                                <DatasetLinked />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <>
                                                        {displayName ?? defaultProjectNameText}
                                                        {roles && <ProjectRoleLabel projectRoles={roles} />}
                                                    </>
                                                }
                                                secondary={id}
                                            />
                                        </MenuItem>
                                    ))}
                            </Menu>
                        </>
                    )}
                </Box>
                <Box sx={{ pl: 2, pr: 1, borderColor: 'grey.400', display: 'flex', alignItems: 'center' }}>
                    <DatasetLinked />
                    <Box sx={{ ml: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: '3px' }}>
                            {displayName && (
                                <Typography
                                    sx={{
                                        fontWeight: 700,
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: 'grey.800',
                                    }}
                                >
                                    {displayName ?? defaultProjectNameText}
                                </Typography>
                            )}
                            {roles && <ProjectRoleLabel projectRoles={roles} />}
                        </Box>
                        <Typography sx={{ mb: '5px' }}>{projectId}</Typography>
                        {projectId && loggedInUser?.email && (
                            <Link
                                href={`https://console.cloud.google.com/bigquery/?p=${projectId}&project=${projectId}&authUser=${loggedInUser.email}`}
                                target="_blank"
                                sx={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}
                            >
                                View PAD in BigQuery
                                <LaunchIcon sx={{ ml: 1, fontSize: '14px' }} />
                            </Link>
                        )}
                    </Box>
                </Box>
                <Divider sx={{ mt: 2 }} />
                {navList}
            </Drawer>
        </Box>
    );
}
