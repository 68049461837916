import { useState } from 'react';
import { Chip, Popover, Typography } from '@mui/material';

export default function PreviewLabel() {
    const [anchorEl, setAnchorEl] = useState(null);
    const POPOVER_ID = 'preview-popover';
    const isPreviewPopoverOpen = Boolean(anchorEl);

    const handlePreviewChipClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePreviewPopover = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Chip
                aria-describedby={POPOVER_ID}
                label="Preview"
                size="small"
                sx={{ ml: 1, fontWeight: 500, color: 'primary.main', backgroundColor: 'primary.light' }}
                onClick={handlePreviewChipClick}
            />
            <Popover
                id={POPOVER_ID}
                open={isPreviewPopoverOpen}
                anchorEl={anchorEl}
                onClose={handleClosePreviewPopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <Typography sx={{ p: 2 }}>
                    PAD UI is currently in preview. Features and functionality may change without notice.
                </Typography>
            </Popover>
        </>
    );
}
