import { Button } from '@mui/material';
import axios from 'axios';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { GCP_WORKFLOW_IDS } from '@cta-pond/constants';

import EmptyStatePlaceholder from 'components/EmptyStatePlaceholder';
import GcpWorkflowExecutionsTable from 'components/GcpWorkflowExecutionsTable';
import PageHeader from 'components/PageHeader';
import SkeletonLoaderList from 'components/SkeletonLoaderList';
import { getAuthedFetchConfig } from 'helpers/auth';
import useAsync from 'hooks/useAsync';

export default function RETLJobsIndexPage() {
    const { projectId } = useParams();

    const buttonLabel = 'Create new rETL job';

    const requestToFetchRETLWorkflowExecutions = useAsync(async () => {
        const authedRequestConfig = await getAuthedFetchConfig();

        const { data } = await axios.get('/api/gcp-workflow-executions', {
            params: {
                projectId,
                workflowId: GCP_WORKFLOW_IDS.NGPVAN_RETL,
            },
            ...authedRequestConfig,
        });

        return data;
    });

    useEffect(() => {
        requestToFetchRETLWorkflowExecutions.perform();
    }, []);

    return (
        <>
            <PageHeader
                title="rETL Jobs"
                action={
                    <Button component={Link} to="new" variant="contained" color="secondary" aria-label={buttonLabel}>
                        {buttonLabel}
                    </Button>
                }
            />
            <section>
                {requestToFetchRETLWorkflowExecutions.hasNotBeenPerformed ||
                requestToFetchRETLWorkflowExecutions.isRunning ? (
                    <SkeletonLoaderList count={5} height={75} />
                ) : requestToFetchRETLWorkflowExecutions.didFail ? (
                    <EmptyStatePlaceholder
                        title={'Oops! Something went wrong.'}
                        subtitle={'Try refreshing the page or contact support if the problem persists.'}
                    />
                ) : requestToFetchRETLWorkflowExecutions.result?.length === 0 ? (
                    <EmptyStatePlaceholder
                        title={'No rETL jobs to show'}
                        subtitle={'Once you create your first rETL job it will show up here.'}
                    />
                ) : (
                    <GcpWorkflowExecutionsTable executions={requestToFetchRETLWorkflowExecutions.result} />
                )}
            </section>
        </>
    );
}
