import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useController } from 'react-hook-form';

export default function SyncPointInput({ input, control, ...rest }) {
    SyncPointInput.propTypes = {
        input: PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            type: PropTypes.string,
            isSecret: PropTypes.bool,
            helperText: PropTypes.string,
        }).isRequired,
        control: PropTypes.object.isRequired,
    };

    const inputDefaults = { required: true, type: 'text', isSecret: false, helperText: ' ' };
    const { name, label, type, isSecret, helperText } = { ...inputDefaults, ...input };

    const defaultRules = {
        required: true,
        minLength: 2,
        maxLength: 100,
    };

    const defaultStyles = { mb: 1, width: '100%' };

    const {
        field: { onChange, onBlur, ref },
        fieldState: { invalid, isTouched },
    } = useController({
        control,
        name,
        rules: { ...defaultRules, validate: (value) => Boolean(value?.value) },
    });

    const [isSecretValueVisible, setIsSecretValueVisible] = useState(false);

    const inputType = isSecret && !isSecretValueVisible ? 'password' : type;

    const maybeInputProps = isSecret
        ? {
              endAdornment: (
                  <InputAdornment position="end">
                      <IconButton
                          aria-label={`Toggle ${label} input visibility`}
                          onClick={() => setIsSecretValueVisible(!isSecretValueVisible)}
                          onMouseDown={() => setIsSecretValueVisible(!isSecretValueVisible)}
                      >
                          {isSecretValueVisible ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                  </InputAdornment>
              ),
          }
        : null;

    return (
        <>
            <TextField
                {...rest}
                name={name}
                label={label}
                type={inputType}
                inputProps={defaultRules}
                sx={defaultStyles}
                required={true}
                helperText={helperText}
                error={isTouched && invalid}
                inputRef={ref}
                onBlur={onBlur}
                /* This mapping is necessary to pass the relevant value to the `onChange` handler */
                onChange={(event) => {
                    onChange({
                        name,
                        isSecret,
                        value: event.target.value,
                    });
                }}
                InputProps={maybeInputProps}
            />
        </>
    );
}
