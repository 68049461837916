import {
    DEFINED_PROJECT_FEATURE_FLAGS,
    DERIVED_PROJECT_FEATURE_FLAGS,
    PROJECT_FEATURE_FLAGS,
    USER_ROLES,
} from '@cta-pond/constants';
import { useRouteLoaderData } from 'react-router-dom';

function mapProjectFeatureFlagsForCurrentUser(projectFeatureFlags, userProjectRoles) {
    const currentUsersProjectLevelFeatureFlagsByRole = {};

    Object.values(DEFINED_PROJECT_FEATURE_FLAGS).forEach((flag) => {
        const featureFlagRoles = projectFeatureFlags[flag];

        if (!featureFlagRoles) {
            currentUsersProjectLevelFeatureFlagsByRole[flag] = false;
            return;
        }

        if (
            (featureFlagRoles[USER_ROLES.ADMIN] && userProjectRoles[USER_ROLES.ADMIN]) ||
            (featureFlagRoles[USER_ROLES.EDITOR] && userProjectRoles[USER_ROLES.EDITOR]) ||
            (featureFlagRoles[USER_ROLES.CONTRIBUTOR] && userProjectRoles[USER_ROLES.CONTRIBUTOR]) ||
            (featureFlagRoles[USER_ROLES.VIEWER] && userProjectRoles[USER_ROLES.VIEWER])
        ) {
            currentUsersProjectLevelFeatureFlagsByRole[flag] = true;
            return;
        }

        currentUsersProjectLevelFeatureFlagsByRole[flag] = false;
    });

    return currentUsersProjectLevelFeatureFlagsByRole;
}

export default function useFeatureFlags() {
    const { activeProjectDetails } = useRouteLoaderData('projectRoot');
    const { featureFlags = {}, roles: activeProjectRolesForUser } = activeProjectDetails;

    const definedFeatureFlags = mapProjectFeatureFlagsForCurrentUser(featureFlags ?? {}, activeProjectRolesForUser);

    const derivedFeatureFlags = {
        [DERIVED_PROJECT_FEATURE_FLAGS.IS_MATCHING_ENABLED]:
            definedFeatureFlags[PROJECT_FEATURE_FLAGS.IS_CATALIST_MATCHING_ENABLED] ||
            definedFeatureFlags[PROJECT_FEATURE_FLAGS.IS_TARGETSMART_MATCHING_ENABLED],
        [DERIVED_PROJECT_FEATURE_FLAGS.ARE_BOTH_MATCHING_PROVIDERS_ENABLED]:
            definedFeatureFlags[PROJECT_FEATURE_FLAGS.IS_CATALIST_MATCHING_ENABLED] &&
            definedFeatureFlags[PROJECT_FEATURE_FLAGS.IS_TARGETSMART_MATCHING_ENABLED],
    };

    return { ...definedFeatureFlags, ...derivedFeatureFlags };
}
