import { where, or } from 'firebase/firestore/lite';
import { auth } from '../firebase.js';
import { getAllDocumentsInCollection } from 'helpers/firestore';
import { USER_ROLES } from '@cta-pond/constants';

export async function getLoggedInUser() {
    await auth.authStateReady();
    return auth?.currentUser;
}

export async function getAuthedFetchConfig() {
    const currentUser = await getLoggedInUser();
    const idToken = await currentUser?.getIdToken();

    if (!idToken) {
        throw new Error('No ID token found to populate request headers. User may not be authenticated');
    }

    return {
        headers: {
            Authorization: `Bearer ${idToken}`,
        },
    };
}

export async function getLoggedInUserWithCustomClaims() {
    const loggedInUser = await getLoggedInUser();

    if (!loggedInUser) {
        return null;
    }

    const { claims = {} } = await loggedInUser.getIdTokenResult();

    return {
        ...loggedInUser,
        claims,
    };
}

export async function getDecoratedProjectsForLoggedInUser() {
    const loggedInUser = await getLoggedInUser();

    if (!loggedInUser) {
        return null;
    }

    const { email } = loggedInUser;

    const allUserRoles = Object.values(USER_ROLES);

    const allProjectsForUser = await getAllDocumentsInCollection(
        'projects',
        or(...allUserRoles.map((role) => where(`usersByRole.${role}`, 'array-contains', email))),
    );

    return allProjectsForUser.map((project) => {
        const { usersByRole, partnerId, displayName } = project;
        const roles = {};

        allUserRoles.forEach((role) => (roles[role] = usersByRole[role].includes(email)));

        return {
            ...project,
            displayName: displayName || partnerId,
            roles,
        };
    });
}
