import { Box, Button, Typography } from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useRouteLoaderData } from 'react-router-dom';

import { GCP_WORKFLOW_IDS, REMOTE_CONFIG_PARAMETERS, SNACKBAR_VARIANTS } from '@cta-pond/constants';

import BigQueryTableSelector from 'components/BigQueryTableSelector';
import FieldMatcher from 'components/FieldMatcher';
import InlineMessage from 'components/InlineMessage';
import PageHeader from 'components/PageHeader';
import SyncNameInput from 'components/SyncNameInput';
import SyncWizard from 'components/SyncWizard';
import SyncWizardFooterWrapper from 'components/SyncWizardFooterWrapper';
import { getAuthedFetchConfig } from 'helpers/auth';
import useAsync from 'hooks/useAsync';
import useRemoteConfigJSON from 'hooks/useRemoteConfigJSON';

export default function NewRETLJobPage() {
    const [activeStep, setActiveStep] = useState(0);
    const [jobName, setJobName] = useState('');
    const [isJobNameValid, setIsJobNameValid] = useState(false);
    const [didUserClickNext, setDidUserClickNext] = useState(false);
    const [didUserClickSubmit, setDidUserClickSubmit] = useState(false);
    const [sourceDataset, setSourceDataset] = useState(null);
    const [sourceTable, setSourceTable] = useState(null);
    const [matchedFields, setMatchedFields] = useState({});
    const { fields, fieldMatchingHelperText, invalidMatchedFieldsMessage } = useRemoteConfigJSON(
        REMOTE_CONFIG_PARAMETERS.VAN_RETL_COPY_AND_CONFIG,
    );
    const { activeProjectDetails } = useRouteLoaderData('projectRoot');
    const { projectId } = useParams();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const isStepOneValid = isJobNameValid && Boolean(sourceDataset) && Boolean(sourceTable);

    const isStepTwoValid = useMemo(() => {
        const areAllRequiredFieldsMatched = fields.every(
            ({ name, isRequired }) => !isRequired || Boolean(matchedFields[name]),
        );
        const matchedValues = Object.values(matchedFields);
        const areMatchedValuesValid = matchedValues.every(Boolean) && matchedValues.length > 0;

        return areAllRequiredFieldsMatched && areMatchedValuesValid;
    }, [fields, matchedFields]);

    const handleJobNameChange = (value) => {
        setJobName(value);
    };

    const handleIsJobNameValidChange = (value) => {
        setIsJobNameValid(value);
    };

    const handleSelectDataset = (event, option) => {
        setSourceTable(null);
        setSourceDataset(option ?? null);
    };

    const handleSelectTable = (event, option) => {
        setSourceTable(option ?? null);
    };

    const handleNext = () => {
        setDidUserClickNext(true);

        if (!isStepOneValid) {
            return;
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleMatchedFieldChange = (field, newMatch) => {
        if (!newMatch) {
            const { [field]: _, ...rest } = matchedFields;
            setMatchedFields(rest);
            return;
        }

        setMatchedFields({ ...matchedFields, [field]: newMatch });
    };

    const handleSubmit = () => {
        setDidUserClickSubmit(true);

        if (!isStepTwoValid) {
            return;
        }

        requestToSubmitNewVANRETLExecution.perform();
    };

    const resetStepTwo = () => {
        setDidUserClickSubmit(false);
        setMatchedFields({});
    };

    useEffect(() => {
        requestToFetchAvailableColumns.perform();
    }, [sourceTable]);

    const requestToFetchAvailableColumns = useAsync(async () => {
        resetStepTwo();

        if (!sourceTable) {
            return [];
        }

        const authedRequestConfig = await getAuthedFetchConfig();

        const { data = [] } = await axios.get(
            `/api/big-query-schema?finder=columnsInTable&projectId=${projectId}&datasetId=${sourceDataset}&tableId=${sourceTable}`,
            authedRequestConfig,
        );

        return data.map(({ name }) => name);
    });

    const requestToSubmitNewVANRETLExecution = useAsync(async () => {
        const authedRequestConfig = await getAuthedFetchConfig();

        const { data = {} } = await axios.post(
            '/api/gcp-workflow-executions',
            {
                project_id: projectId,
                workflow_id: GCP_WORKFLOW_IDS.NGPVAN_RETL,
                job_name: jobName,
                dataset_id: sourceDataset,
                table_id: sourceTable,
                fields: matchedFields,
                van_app_name: activeProjectDetails?.defaultVANAppName,
                van_database_mode: 1,
                bulk_import_job_type: 'upsert_contacts',
            },
            authedRequestConfig,
        );

        enqueueSnackbar('NGP VAN rETL job created successfully', { variant: SNACKBAR_VARIANTS.SUCCESS });
        navigate(`/projects/${projectId}/retl-jobs`);

        return data;
    });

    const stepTwoVisibleErrorMessage = requestToSubmitNewVANRETLExecution.didFail
        ? requestToSubmitNewVANRETLExecution.error.message
        : didUserClickSubmit && !isStepTwoValid
          ? invalidMatchedFieldsMessage
          : null;

    const Step1 = (
        <>
            <SyncNameInput
                syncName={jobName}
                onSyncNameChange={handleJobNameChange}
                isSyncNameValid={isJobNameValid}
                onIsSyncNameValidChange={handleIsJobNameValidChange}
                showAllErrors={didUserClickNext}
            />
            <BigQueryTableSelector
                projectId={projectId}
                sourceDataset={sourceDataset}
                onSelectDataset={handleSelectDataset}
                sourceTable={sourceTable}
                onSelectTable={handleSelectTable}
                showAllErrors={didUserClickNext}
            />
            <SyncWizardFooterWrapper sxOverrides={{ justifyContent: 'end' }}>
                <Button variant="outlined" onClick={handleNext}>
                    Next: Match NGP VAN Fields
                </Button>
            </SyncWizardFooterWrapper>
        </>
    );

    const Step2 = (
        <>
            <Box>
                <Typography variant="h4" sx={{ mb: 1 }}>
                    Match columns to NGP VAN fields
                </Typography>
                <Typography variant="subtitle2" sx={{ mb: 2 }}>
                    Select columns from <code>{sourceTable ?? 'table'}</code> to match to NGP VAN fields.
                </Typography>
                {fieldMatchingHelperText && (
                    <InlineMessage type="info" message={fieldMatchingHelperText} sxOverrides={{ mb: 2 }} />
                )}
                <FieldMatcher
                    allFields={fields}
                    matchedFields={matchedFields}
                    matchOptions={requestToFetchAvailableColumns.result || []}
                    fieldValueKey="name"
                    fieldLabelKey="description"
                    headerLabels={['Field', 'NGP VAN Name', 'Column']}
                    onMatchChange={handleMatchedFieldChange}
                    areMatchOptionsLoading={requestToFetchAvailableColumns.isRunning}
                    showAllErrors={didUserClickSubmit}
                />
            </Box>
            <SyncWizardFooterWrapper sxOverrides={{ justifyContent: 'between' }}>
                {stepTwoVisibleErrorMessage && (
                    <InlineMessage
                        type="error"
                        message={stepTwoVisibleErrorMessage}
                        sxOverrides={{ justifySelf: 'start', maxWidth: '50%' }}
                    />
                )}
                <Button color="inherit" onClick={handleBack} sx={{ mr: 1, ml: 'auto', alignSelf: 'center' }}>
                    Back
                </Button>
                <Button variant="contained" sx={{ alignSelf: 'center' }} onClick={handleSubmit}>
                    Submit NGP VAN rETL Job
                </Button>
            </SyncWizardFooterWrapper>
        </>
    );

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <PageHeader title="Create a new rETL Job" subtitle={'Move data from PAD to NGP VAN'} />
            <SyncWizard
                steps={[
                    {
                        label: 'Select source table',
                        content: Step1,
                    },
                    {
                        label: 'Match NGP VAN fields',
                        content: Step2,
                    },
                ]}
                activeStepIndex={activeStep}
                isLoading={requestToSubmitNewVANRETLExecution.isRunning}
                loadingMessage={'Submitting NGP VAN rETL job...'}
                loadingBackdropTestId={'submit-retl-job-backdrop'}
            />
        </Box>
    );
}
