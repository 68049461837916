import { Skeleton } from '@mui/material';

const DEFAULT_SKELETON_COUNT = 3;
const DEFAULT_SKELETON_HEIGHT = 50;

export default function SkeletonLoaderList({ count = DEFAULT_SKELETON_COUNT, height = DEFAULT_SKELETON_HEIGHT }) {
    return Array(count)
        .fill()
        .map((_, skeletonIdx) => (
            <Skeleton
                data-testid={`skeleton-loader-${skeletonIdx}`}
                key={skeletonIdx}
                variant="rectangular"
                height={height}
                sx={{ mt: 2 }}
            />
        ));
}
