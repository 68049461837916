import { firestore as db } from '../firebase';
import { collection, getDocs, query } from 'firebase/firestore/lite';

/**
 * Abstract away the process of fetching & mapping all documents in a collection
 * Include ID of each document in the returned objects
 * @param {string} collectionName
 * @param  {...any} queryConstraints
 * @returns {Promise<Array<Object>>}
 */
export async function getAllDocumentsInCollection(collectionName, ...queryConstraints) {
    const collectionRef = collection(db, collectionName);
    const q = query(collectionRef, ...queryConstraints);

    const querySnapshot = await getDocs(q);

    return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id ?? null, path: doc?.ref?.path ?? null }));
}
