import { useState } from 'react';

/**
 * Hook to run any async function and maintain its loading / error state
 * @todo: make request cancelable, e.g. when calling component is destroyed.
 *
 * @param {Function} asyncFunction
 * @param {Object} options
 * @returns {Object} { perform, performCount, result, didSucceed, isRunning, setIsRunning, error, hasError, hasBeenPerformed, hasNotBeenPerformed }
 */
export default function useAsync(asyncFunction) {
    const [result, setResult] = useState(null);
    const [didSucceed, setDidSucceed] = useState(false);
    const [isRunning, setIsRunning] = useState(false);
    const [error, setError] = useState(null);
    const [performCount, setPerformCount] = useState(0);

    const reset = () => {
        setResult(null);
        setDidSucceed(false);
        setIsRunning(false);
        setError(null);
    };

    const perform = async (...args) => {
        reset();
        setIsRunning(true);
        setPerformCount(performCount + 1);

        let result = null;

        try {
            const result = await asyncFunction(...args);
            setResult(result);
            setDidSucceed(true);
        } catch (error) {
            setError(error?.response?.data || error);
        } finally {
            setIsRunning(false);
        }

        return result;
    };

    return {
        perform,
        reset,
        setIsRunning,
        result,
        performCount,
        didSucceed,
        isRunning,
        error,
        didFail: Boolean(error),
        hasBeenPerformed: performCount > 0,
        hasNotBeenPerformed: performCount === 0,
    };
}
