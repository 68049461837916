import { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

import { GCP_WORKFLOW_EXECUTION_MAX_NAME_LENGTH } from '@cta-pond/constants';

export default function SyncNameInput({
    syncName,
    onSyncNameChange,
    isSyncNameValid,
    onIsSyncNameValidChange,
    showAllErrors = false,
    label = 'Job Name',
    helperText = 'Please input a job name.',
    maxCharCount = GCP_WORKFLOW_EXECUTION_MAX_NAME_LENGTH,
}) {
    SyncNameInput.propTypes = {
        syncName: PropTypes.string.isRequired,
        onSyncNameChange: PropTypes.func.isRequired,
        isSyncNameValid: PropTypes.bool.isRequired,
        onIsSyncNameValidChange: PropTypes.func.isRequired,
        showAllErrors: PropTypes.bool,
        label: PropTypes.string,
        helperText: PropTypes.string,
        maxCharCount: PropTypes.number,
    };

    const [isNameInputDirty, setIsNameInputDirty] = useState(false);

    const showSyncNameFieldError = !isSyncNameValid && (isNameInputDirty || showAllErrors);
    const syncNameInfoMessage = `${maxCharCount} characters max`;

    const handleSyncNameChange = (event) => {
        const value = event?.target?.value;
        const isValueValid = Boolean(value) && value.length <= GCP_WORKFLOW_EXECUTION_MAX_NAME_LENGTH;

        onIsSyncNameValidChange(isValueValid);
        onSyncNameChange(value);
    };

    return (
        <TextField
            fullWidth
            sx={{ mb: 1 }}
            error={showSyncNameFieldError}
            onBlur={() => setIsNameInputDirty(true)}
            label={label}
            helperText={showSyncNameFieldError ? `${helperText} ${syncNameInfoMessage}.` : syncNameInfoMessage}
            value={syncName}
            onChange={handleSyncNameChange}
            inputProps={{
                maxLength: maxCharCount,
            }}
        />
    );
}
