import { SYNC_POINT_ICON_IMAGE_FILES } from '@cta-pond/constants';
import { useRouteLoaderData } from 'react-router-dom';

export default function useSyncPointMetadata(pointType) {
    const { syncPointConfigurations } = useRouteLoaderData('projectRoot');

    const staticLogoFile = SYNC_POINT_ICON_IMAGE_FILES[pointType];
    const logoUrl = staticLogoFile ? `/${staticLogoFile}` : null;

    const defaults = {
        singularName: 'Unknown source type',
        pluralName: 'Unknown source type',
        logoUrl,
        inputs: [],
    };

    return {
        ...defaults,
        ...syncPointConfigurations[pointType],
    };
}
