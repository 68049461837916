import CloseIcon from '@mui/icons-material/Close';
import LaunchIcon from '@mui/icons-material/Launch';
import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

import {
    GCP_WORFKLOW_EXECUTION_STATUS_LABELS,
    GCP_WORKFLOW_EXECUTION_STATUSES,
    GCP_WORKFLOW_EXECUTION_UI_TABLE_COLUMNS,
    VENDOR_NAMES,
} from '@cta-pond/constants';

const formatDurationString = (durationString) => {
    const durationInSeconds = parseFloat(durationString.slice(0, -1));
    const minutes = Math.floor(durationInSeconds / 60);
    const maybeMinutesString = minutes > 0 ? `${minutes}m ` : '';
    const secondsString = `${Math.floor(durationInSeconds % 60)}s`;

    return `${maybeMinutesString}${secondsString}`;
};

const generateBigQueryLinkFromExecutionResult = ({ tableReference: { projectId, datasetId, tableId } = {} } = {}) =>
    `https://console.cloud.google.com/bigquery?project=${projectId}&ws=!1m5!1m4!4m3!1s${projectId}!2s${datasetId}!3s${tableId}`;

const DialogCloseButton = ({ onClose }) => {
    DialogCloseButton.propTypes = {
        onClose: PropTypes.func.isRequired,
    };

    return (
        <IconButton
            aria-label="Close dialog"
            onClick={onClose}
            sx={{
                position: 'absolute',
                right: 12,
                top: 12,
                color: 'grey.500',
            }}
        >
            <CloseIcon />
        </IconButton>
    );
};

export default function GcpWorkflowExecutionsTable({ executions, optionalVisibleColumns = [] }) {
    GcpWorkflowExecutionsTable.propTypes = {
        executions: PropTypes.array.isRequired,
        optionalVisibleColumns: PropTypes.array,
    };

    const [idOfExecutionWithErrorDialogVisible, setIdOfExecutionWithErrorDialogVisible] = useState(null);
    const [idOfExecutionWithDetailsDialogVisible, setIdOfExecutionWithDetailsDialogVisible] = useState(null);

    const isVendorFieldVisible = optionalVisibleColumns.includes(GCP_WORKFLOW_EXECUTION_UI_TABLE_COLUMNS.VENDOR);

    const statusBadgeColorByState = {
        [GCP_WORKFLOW_EXECUTION_STATUSES.ACTIVE]: 'secondary',
        [GCP_WORKFLOW_EXECUTION_STATUSES.SUCCEEDED]: 'success',
        [GCP_WORKFLOW_EXECUTION_STATUSES.FAILED]: 'error',
    };

    return (
        <TableContainer component={Paper} sx={{ overflowX: 'auto' }} square>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Job Name</TableCell>
                        {isVendorFieldVisible && <TableCell>Vendor</TableCell>}
                        <TableCell>ID</TableCell>
                        <TableCell>Date Started</TableCell>
                        <TableCell>Duration</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell align="right" />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {executions.map(({ state, startTime, duration, metadata = {}, result, error, argument }) => (
                        <TableRow key={metadata.executionId}>
                            <TableCell sx={{ maxWidth: '400px' }}>{metadata?.jobName ?? '-'}</TableCell>
                            {isVendorFieldVisible && <TableCell>{VENDOR_NAMES[metadata?.vendorId] ?? '-'}</TableCell>}
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>{metadata.executionId}</TableCell>
                            <TableCell>{new Date(startTime).toLocaleDateString()}</TableCell>
                            <TableCell>{duration ? formatDurationString(duration) : '-'}</TableCell>
                            <TableCell sx={{ fontWeight: 600 }}>
                                {
                                    <Chip
                                        variant="outlined"
                                        label={GCP_WORFKLOW_EXECUTION_STATUS_LABELS[state] ?? 'Unknown'}
                                        color={statusBadgeColorByState[state] ?? 'default'}
                                    />
                                }
                            </TableCell>
                            <TableCell>
                                <Box sx={{ whiteSpace: 'nowrap', display: 'flex', justifyContent: 'flex-end' }}>
                                    {state === GCP_WORKFLOW_EXECUTION_STATUSES.SUCCEEDED && result && (
                                        <Button
                                            component={Link}
                                            href={generateBigQueryLinkFromExecutionResult(result)}
                                            target="_blank"
                                            sx={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}
                                        >
                                            View results in BigQuery <LaunchIcon sx={{ ml: 1, fontSize: '16px' }} />
                                        </Button>
                                    )}
                                    {state === GCP_WORKFLOW_EXECUTION_STATUSES.FAILED && (
                                        <>
                                            <Button
                                                sx={{ fontWeight: 600 }}
                                                onClick={() =>
                                                    setIdOfExecutionWithErrorDialogVisible(metadata.executionId)
                                                }
                                            >
                                                View Error Details
                                            </Button>
                                            <Dialog
                                                onClose={() => setIdOfExecutionWithErrorDialogVisible(null)}
                                                open={metadata.executionId === idOfExecutionWithErrorDialogVisible}
                                            >
                                                <DialogTitle>Error Message</DialogTitle>
                                                <DialogCloseButton
                                                    onClose={() => setIdOfExecutionWithErrorDialogVisible(null)}
                                                />
                                                <DialogContent>
                                                    <Box sx={{ backgroundColor: 'grey.300', p: 2 }}>
                                                        <Typography sx={{ wordWrap: 'break-word' }}>
                                                            {error.context}
                                                        </Typography>
                                                        {error.payload?.body && (
                                                            <Typography sx={{ wordWrap: 'break-word', mt: 2 }}>
                                                                {JSON.stringify(error.payload?.body)}
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                </DialogContent>
                                            </Dialog>
                                        </>
                                    )}
                                    <>
                                        <Button
                                            sx={{ fontWeight: 600 }}
                                            onClick={() =>
                                                setIdOfExecutionWithDetailsDialogVisible(metadata.executionId)
                                            }
                                        >
                                            View Job Details
                                        </Button>
                                        <Dialog
                                            onClose={() => setIdOfExecutionWithDetailsDialogVisible(null)}
                                            open={metadata.executionId === idOfExecutionWithDetailsDialogVisible}
                                        >
                                            <DialogTitle>Job Details </DialogTitle>
                                            <DialogCloseButton
                                                onClose={() => setIdOfExecutionWithDetailsDialogVisible(null)}
                                            />
                                            <DialogContent>
                                                <Box sx={{ backgroundColor: 'grey.300', p: 2, mb: 2 }}>
                                                    <Typography sx={{ wordWrap: 'break-word' }}>
                                                        {JSON.stringify(argument)}
                                                    </Typography>
                                                </Box>
                                            </DialogContent>
                                        </Dialog>
                                    </>
                                </Box>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
