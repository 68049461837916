import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SuccessOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningOutlineIcon from '@mui/icons-material/WarningAmberOutlined';
import InfoIcon from '@mui/icons-material/Info';

export default function InlineMessage({ message, type = 'info', sxOverrides = {} }) {
    InlineMessage.propTypes = {
        message: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['info', 'error', 'success', 'warning']),
        sxOverrides: PropTypes.object,
    };

    const icons = {
        info: <InfoIcon />,
        error: <ErrorOutlineIcon />,
        success: <SuccessOutlineIcon />,
        warning: <WarningOutlineIcon />,
    };

    const colors = {
        info: 'info.dark',
        error: 'error.dark',
        success: 'success.dark',
        warning: 'warning.dark',
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                color: colors[type],
                ...sxOverrides,
            }}
        >
            <Box sx={{ marginRight: 1 }}>{icons[type]}</Box>
            <Typography sx={{ fontWeight: 500, mb: '6px' }}>{message}</Typography>
        </Box>
    );
}
