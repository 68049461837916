import { Paper, Typography } from '@mui/material';
import NewSyncStepOneForm from 'components/NewSyncStepOneForm';

export default function NewSyncPage() {
    return (
        <Paper component={'section'} square elevation={1} sx={{ p: 4, position: 'relative', mx: 12 }}>
            <Typography variant="h2" sx={{ mb: 1, textAlign: 'center' }}>
                Create a new sync
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 3, textAlign: 'center' }}>
                Move data between your providers
            </Typography>
            <NewSyncStepOneForm />
        </Paper>
    );
}
