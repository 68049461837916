import { useContext } from 'react';
import LayoutContext from 'contexts/LayoutContext';

const useLayoutState = () => {
    const context = useContext(LayoutContext);

    if (!context) throw new Error('context must be use inside provider');

    return context;
};

export default useLayoutState;
