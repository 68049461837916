import { useState } from 'react';
import { Chip, Popover, Typography } from '@mui/material';

export default function DemoLabel() {
    const [anchorEl, setAnchorEl] = useState(null);
    const POPOVER_ID = 'demo-popover';
    const isDemoPopoverOpen = Boolean(anchorEl);

    const handleDemoChipClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseDemoPopover = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Chip
                aria-describedby={POPOVER_ID}
                label="Demo"
                size="small"
                sx={{
                    ml: 1,
                    fontWeight: 500,
                    color: 'primary.main',
                    border: 1,
                    borderColor: 'primary.main',
                    backgroundColor: 'transparent',
                }}
                onClick={handleDemoChipClick}
            />
            <Popover
                id={POPOVER_ID}
                open={isDemoPopoverOpen}
                anchorEl={anchorEl}
                onClose={handleCloseDemoPopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{ p: 2 }}>
                    This is a non-functioning prototype using fake data. Is is for demo purposes only.
                </Typography>
            </Popover>
        </>
    );
}
